import React, { FC, useEffect } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import { TaxonomyType } from "data/types";
import ncNanoId from "utils/ncNanoId";
import CardCategory3 from "components/CardCategory3/CardCategory3";
import CardCategory4 from "components/CardCategory4/CardCategory4";
import NextPrev from "shared/NextPrev/NextPrev";
import CardCategory5 from "components/CardCategory5/CardCategory5";
import {Link} from "react-router-dom";
import NcImage from "../../shared/NcImage/NcImage";
import convertNumbThousand from "../../utils/convertNumbThousand";

export interface SectionSliderNewCategoriesProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  categories?: TaxonomyType[];
  categoryCardType?: "card3" | "card4" | "card5";
  itemPerRow?: 4 | 5;
  sliderStyle?: "style1" | "style2";
  id?:any
}

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "/accommodation",
    name: "Accommodation",
    taxonomy: "category",
    count: 17288,
    thumbnail:
      "https://images.pexels.com/photos/2581922/pexels-photo-2581922.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "2",
    href: "/holiday-house",
    name: "HolidayHouse",
    taxonomy: "category",
    count: 2118,
    thumbnail:
      "https://images.pexels.com/photos/2351649/pexels-photo-2351649.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "3",
    href: "/short-term-rent",
    name: "Short Term Rentals",
    taxonomy: "category",
    count: 36612,
    thumbnail:
      "https://images.pexels.com/photos/962464/pexels-photo-962464.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "4",
    href: "/apartment",
    name: "Service Apartment",
    taxonomy: "category",
    count: 2118,
    thumbnail:
        "https://images.pexels.com/photos/248837/pexels-photo-248837.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
];

const SectionSliderNewCategories: FC<SectionSliderNewCategoriesProps> = ({
  heading = "Heading of sections",
  subHeading = "",
  className = "",
  itemClassName = "",
  categories = DEMO_CATS,
  itemPerRow = 5,
  categoryCardType = "card3",
  sliderStyle = "style1",
    id,
}) => {
  const UNIQUE_CLASS = "glide_" + ncNanoId();

  useEffect(() => {
    if (document.querySelector(`.${UNIQUE_CLASS}`)) {
      new Glide(`.${UNIQUE_CLASS}`, {
        perView: itemPerRow,
        gap: 32,
        bound: true,
        breakpoints: {
          1280: {
            perView: itemPerRow - 1,
          },
          1024: {
            gap: 20,
            perView: itemPerRow - 1,
          },
          768: {
            gap: 20,
            perView: itemPerRow - 2,
          },
          640: {
            gap: 20,
            perView: itemPerRow - 3,
          },
          500: {
            gap: 20,
            perView: 1.3,
          },
        },
      }).mount();
    }
  }, []);

  const renderCard = (item: TaxonomyType, index: number) => {
    switch (categoryCardType) {
      case "card3":
        return <CardCategory3 taxonomy={item} />;
      case "card4":
        return <CardCategory4 taxonomy={item} />;
      case "card5":
        return <CardCategory5 taxonomy={item} />;
      default:
        return <CardCategory3 taxonomy={item} />;
    }
  };

  return (
    // <div className={`nc-SectionSliderNewCategories ${className}`}>
    //   <div className={`${UNIQUE_CLASS} flow-root`}>
    //     <Heading
    //       desc={subHeading}
    //       hasNextPrev={sliderStyle === "style1"}
    //       isCenter={sliderStyle === "style2"}
    //     >
    //       {heading}
    //     </Heading>
    //     <div className="glide__track" data-glide-el="track">
    //       <ul className="glide__slides">
    //         {categories.map((item, index) => (
    //           <li key={index} className={`glide__slide ${itemClassName}`}>
    //             {renderCard(item, index)}
    //           </li>
    //         ))}
    //       </ul>
    //     </div>
    //   </div>
    // </div>
      <div className="relative text-center">


        {/* <div
            className={`nc-BackgroundSection absolute inset-y-0 w-screen xl:max-w-[1340px] 2xl:max-w-screen-2xl left-1/2 transform -translate-x-1/2 xl:rounded-[40px] z-0 bg-neutral-100 dark:bg-black dark:bg-opacity-20`}
          >
          </div> */}

        <div>
          <h2 className={`sm:text-3xl text-3xl md:text-4xl font-semibold mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50`}>
            Accommodation Type
          </h2>

          <ul className="flex  flex-col lg:flex-row justify-between items-center">
            {/* <BackgroundSection /> */}
            {DEMO_CATS.map((item, index) => (
                (id===item.id)? "" :(
                <li key={index} style={{ width: '230.4px', marginRight: '16px' }}>
                  <Link
                      to={item.href}
                      className={`nc-CardCategory3 flex flex-col ${className}`}
                      data-nc-id="CardCategory3"
                  >
                    <div
                        className={`flex-shrink-0 relative w-full aspect-w-5 aspect-h-4 sm:aspect-h-7 h-0 rounded-2xl overflow-hidden group`}
                    >
                      <NcImage
                          src={item.thumbnail}
                          className="object-cover w-full h-full rounded-2xl"
                      />
                      <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
                    </div>
                    <div className="mt-4 truncate mb-4">
                      <h2
                          className={`text-base sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium truncate`}
                      >
                        {item.name}
                      </h2>
                      <span
                          className={`block mt-2 text-sm text-neutral-6000 dark:text-neutral-400`}
                      >
                      {/*{convertNumbThousand(item.count || 0)} properties*/}
                    </span>
                    </div>
                  </Link>
                </li>)
            ))}
          </ul>
        </div>
      </div>
  );
};

export default SectionSliderNewCategories;
