import { useTranslation } from "react-i18next";
// import languageData from "./langData";
import React, {useEffect, useState} from "react";
// import languageData from "./../../i18n"
import AR from "../../images/icons/arabi.png";
import EN from "../../images/icons/eng.png";
export interface LanguageDropdownProps {
  className?: string;
}

const LanguageDropdown: React.FC<LanguageDropdownProps> = ({
  className = "",
}) => {
  const [t, i18n] = useTranslation();
  document.body.dir = i18n.dir();
  // const [img, setImg] = useState(AR);

  // const handelToggle = (lng: any) => {
  const handelToggle = () => {
    let getLang = localStorage.getItem("i18nextLng");

    if (getLang === "en-US") {
      // setImg(AR);
      i18n.changeLanguage("ar");
      localStorage.setItem("i18nextLng", "ar");
      document.body.dir = i18n.dir();
    } else {
      // setImg(EN);
      i18n.changeLanguage("en");
      localStorage.setItem("i18nextLng", "en-US");
      document.body.dir = i18n.dir();
    }
    // i18n.changeLanguage(lng);
  };

  useEffect(() =>{
    handelToggle()
  },[])

  return (
    <React.Fragment>
      <div className="toggle__design">
        {localStorage.getItem("i18nextLng") === "en-US" ? <img onClick={handelToggle} src={AR} alt={"safari"} className="lang__icon"/> : <img onClick={handelToggle} src={EN} alt={"safari"} className="lang__icon"/>}


      </div>
    </React.Fragment>
  );
};

export default LanguageDropdown;
