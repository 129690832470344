import React, {FC, Fragment} from "react";
import MainNav from "./MainNav";
import { Helmet } from "react-helmet";
import {useTranslation} from "react-i18next";

import { useEffect } from "react";
import { useState } from "react";

import SocialsList from "../SocialsList/SocialsList";

export interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
  const [isTop, setisTop] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    window.onscroll = function () {
      scrollFunction();
    };
  }, []);

  function scrollFunction() {
    const $head = document.getElementById("nc-chifis-header");
    if (!$head) return;
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      !!isTop && setisTop(false);
    } else {
      setisTop(true);
    }
  }

  return (
      <Fragment>
        <div className="border-b hidden lg:block bg-white dark:bg-neutral-900 backdrop-blur-2xl bg-opacity-70 dark:bg-opacity-60 shadow-sm dark:border-b dark:border-neutral-700 header__top">
          <div className="container flex items-center justify-between">
            <div className="flex items-center text-xl ">
              <SocialsList/>
            </div>
            <div className="flex   " >
              <span className="flex items-center">
                <i className="las la-envelope text-lg"/>
                <a className='font-semibold text-primary-6000 dark:text-neutral-300' href="mailto:reservation@safarihousehotel.com"> &nbsp; reservation@safarihousehotel.com</a>
                  {/*<p className="pl-2">reservation@safarihousehotel.com</p>*/}
              </span>
              <span className="flex items-center ml-4">
                <i className="las la-phone text-lg "/>
              <p className="pl-2 font-semibold text-primary-6000 dark:text-neutral-300">+96598856952</p>
              </span>
            </div>
          </div>
        </div>
        {/* <ButtonCircle style={{width: '20px', height: '20px', textAlign: 'center', background: 'darkgoldenrod', paddingLeft: '10px'}}><i className="las la-envelope text-sx mr-2" style={{fontSize: '14px', fontWeight: 'bold'}} /></ButtonCircle>  <span style={{paddingLeft: '10px'}}>reservation@safarihousehotel.com</span> */}
        <div
          id="nc-chifis-header"
          className="nc-Header lg:sticky lg:top-0 w-full lg:left-0 lg:right-0 z-40"
        >
          <Helmet>
            <title>{t("Welcome")}</title>
          </Helmet>

          {/* NAV */}
          <MainNav isTop={isTop} />
        </div>
      </Fragment>
  );
};

export default Header;
