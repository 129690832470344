import { FC } from "react";
import { TaxonomyType } from "data/types";
import { NavLink } from "react-router-dom";
// import NcImage from "shared/NcImage/NcImage";

export interface CardCategory1Props {
  className?: string;
  taxonomy: TaxonomyType;
  size?: "large" | "normal";
  path?: string;
}

const CardCategory1: FC<CardCategory1Props> = ({
  className = "",
  size = "normal",
  taxonomy,
  path= '',
}) => {
  const { name, icon } = taxonomy;
  return (
    <NavLink
      to={path}
      className={`nc-CardCategory1 flex items-center hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800 dark:hover:text-neutral-200 ${className}`}
      data-nc-id="CardCategory1"
    >
      <i className={`text-3xl ${icon}`}></i>
      <div>
        <h2
          className={`${
            size === "large" ? "text-lg" : "text-base"
          } text-neutral-900 dark:text-neutral-100 font-semibold pl-4`}
        >
          {name}
        </h2>
      </div>
    </NavLink>
  );
};

export default CardCategory1;
