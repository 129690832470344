import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import Badge from "shared/Badge/Badge";
import axios from "axios";
import config from "utils/config";
import Heading from "../../shared/Heading/Heading";
import BackgroundSection from "../BackgroundSection/BackgroundSection";
// import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import Loading from "components/Loading/Loading";
import roomJsonData from "../../data/jsons/room_types.json";
import GallerySlider from "../GallerySlider/GallerySlider";
import {transcode} from "buffer";
import {useTranslation} from "react-i18next";

let PHOTOS: string[] = [];

const BookingPage = (): JSX.Element => {
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const getRooms = () => {
    setLoading(true);
    axios
      .get(config.roomurl)
      .then(function (response) {
        // handle success
        if (response?.status) {
          const roomData = response?.data?.alltype?.map((item: any) => {
            const test = roomJsonData?.find((rmItem: any) => {
              if (item?.id == rmItem?.id) {
                return rmItem;
              }
            });

            return { ...item, ...test?.data };
          });

          setRooms(roomData);
          setLoading(false);
        }
      })
      .catch(function (error) {
        // handle error
        setLoading(false);
      });
  };

  useEffect(() => {
    getRooms();
  }, []);

  const content = (
    id: number,
    Properties: any,
    name: string,
    price: string,
    images: any[],
    description: string
  ): JSX.Element => {
    return (
      <div
        className={`nc-PropertyCardH group relative  dark:bg-neutral-900 border border-darkBg-800 dark:border-neutral-800  overflow-hidden hover:shadow-xl transition-shadow h-full`}
        data-nc-id="PropertyCardH"
        // key={id}
      >
        <Link
          to={`/property-details/${id}`}
          className="w-full flex flex-col sm:flex-row sm:items-center "
        >
          <div className="flex-shrink-0 ltr:pr-6  rtl:pl-10 w-full h-full sm:w-64 ">
            {/*{console.log(PHOTOS)}*/}
            <GallerySlider
              ratioClass="aspect-w-1 aspect-h-1"
              galleryImgs={images}
              className="w-full h-full  overflow-hidden"
              uniqueID={`PropertyCardH-${Date.now()}-${id}`}
            />
          </div>

          <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start ">
            <div className="space-y-4 w-full">
              <div className="flex items-center space-x-2">
                {/*<Badge name="ADS" color="green"/>*/}
                <h2 className="text-2xl font-medium capitalize ">
                  <span className="line-clamp-2">{t(Properties?.name)}</span>
                </h2>
              </div>

              {/*<div className="inline-grid grid-cols-3  gap-2">*/}
                {/*<div className="flex items-center space-x-2">*/}
                {/*  <div className='flex flex-col shrink-0 grow'>*/}
                {/*    /!*<span className="hidden sm:inline-block ">*!/*/}
                {/*      <i className="las la-bed text-xl " />*/}
                {/*    /!*</span>*!/*/}
                {/*    <p className="text-xl shrink-0 grow">*/}
                {/*      {Properties?.beds}*/}
                {/*    </p>*/}
                {/*  </div>*/}

                {/*  <span className="hidden sm:inline-block  items-end">*/}
                {/*    <i className="las la-user text-2xl " />*/}
                {/*  </span>*/}
                {/*  <span className="hidden sm:inline-block  text-xl ">*/}
                {/*    {Properties?.accommodates}*/}
                {/*  </span>*/}

                {/*  <span className="hidden sm:inline-block items-end">*/}
                {/*    <i className="las la-ruler-horizontal  text-xl " />*/}
                {/*  </span>*/}
                {/*  <span className="hidden sm:inline-block  text-xl  ">*/}
                {/*    {Properties?.size}*/}
                {/*  </span>*/}
                {/*</div>*/}

                <div className='flex justify-between items-center'>
                  <div className='flex flex-col items-center'>
                    <i className="las la-bed text-xl " />
                    <p className="text-xl">{t(Properties?.beds)}</p>
                  </div>
                  <div className='flex flex-col items-center'>
                    <i className="las la-user text-xl " />
                    <p className="text-xl"> {t(Properties?.accommodates)} </p>
                  </div>
                  <div className='flex flex-col items-center'>
                    <i className="las la-ruler-horizontal text-xl " />
                    <p className="text-xl"> {t(Properties?.size)} </p>
                  </div>
                </div>

                {/* ---- */}
              {/*</div>*/}

              <div className="flex w-full justify-between items-end my-6">
                <span className=" my-6 flex items-center justify-center px-3 py-2 border border-blue-900  dark:border-neutral-800 rounded leading-none text-base font-bold text-blue-900 dark:text-neutral-400">
                  KD {price}
                </span>
              </div>
            </div>
          </div>
        </Link>
        <BtnLikeIcon
          colorClass={`bg-neutral-100 dark:bg-neutral-700 hover:bg-neutral-200 hover:bg-opacity-70 text-neutral-6000 `}
          isLiked={false}
          className="absolute right-5 top-5 sm:right-3 sm:top-3 "
        />
      </div>
    );
  };
  return (
    <>
      {/* <div className="">
                <HeroRealEstateSearchForm />
                //  </div> */}
      <div className="container mt-2  space-y-16 lg:space-y-28">
        {/* <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28"> */}
        {/* <SectionHero
                    rightImg={rightImg}
                    heading="Let's Create Memories"
                    btnText="Contact Us!"
                    subHeading="All our properties are hand picked and selected very carefully that meet our standard's criteria to make sure you have best experience."
                /> */}
        <div className="relative py-16">
          <BackgroundSection />
          <div className="nc-SectionGridFeatureProperty relative">
            <div className="flex flex-col relative mt-4 b-mb-0">
              <Heading
                isCenter={true}
                desc={t
                  ("Highly rated rooms to stay that Safari House recommends for you")
                }
              >
                {t("Rooms to stay")}
              </Heading>
              {/* <HeroRealEstateSearchForm className="mb-10" /> */}
            </div>

            {loading ? (
              <Loading />
            ) : (
              <div
                className={`grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-2`}
              >
                {rooms.map(
                  ({ id, name, description, price, images, Properties }) =>
                    content(id, Properties, name, price, images, description)
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingPage;
