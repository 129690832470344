import { Dialog, Transition } from "@headlessui/react";
import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import LocationMarker from "components/AnyReactComponent/LocationMarker";

import StayDatesRangeInput from "components/HeroSearchForm/StayDatesRangeInput";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";

import GoogleMapReact from "google-map-react";
import useWindowSize from "hooks/useWindowResize";
import moment from "moment";
import { Helmet } from "react-helmet";

import Avatar from "shared/Avatar/Avatar";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";

import NcImage from "shared/NcImage/NcImage";

import axios from "axios";
// @ts-ignore
import DOMPurify from "dompurify";
import { CISUI } from "../../utils/CISUI";
import Data from "./../../data/jsons/room_types.json";
import { useTranslation } from "react-i18next";
export interface ListingStayDetailPageProps {
    className?: string;
    isPreviewMode?: boolean;
}

export interface GuestsInput {
    guestAdults?: number | undefined;
    guestChildren?: number | undefined;
    guestInfants?: number | undefined;
    petCount?: number | undefined;
}

const PropertyDetailPage: FC<ListingStayDetailPageProps> = ({
                                                                className = "",
                                                                isPreviewMode = false,
                                                            }) => {
    interface RoomDetailsInface {
        balcony?: string | number;
        bathroom?: string | number;
        bed_room?: string | number;
        com_id?: string | number;
        commission?: string | number;
        created_at?: string | number;
        customer_price?: string | number;
        cleaning_fee?: string | number;
        pet_fee?: string | number;
        details?: string | number;
        discount?: string | number;
        facilities?: any;
        id?: string | number;
        other_room?: string | number;
        owner_price?: string | number;
        priority?: string | number;
        property_id?: string | number;
        property_name?: string;
        remark?: string | number;
        room_name?: string | number;
        room_type?: string | number;
        latitude?: string | undefined;
        longitude?: string;
        address?: string | number;
        size?: string | number;
        status?: string | number;
        updated_at?: string | number;
        user_id?: string | number;
        kitchen?: string | number;
        maximum_guest?: string | number;
        sufa_bed?: string | number;
        check_in?: string;
        check_out?: string;
        room_policy?: string;
        booking_policy?: string;
        cancellation_policy?: string;
    }
    const createMarkup = (html: any) => {
        return {
            __html: DOMPurify.sanitize(html),
        };
    };

    interface AmenitiesInface {
        name?: string;
        icon?: string;
    }

    // interface AllImageInface {
    //     image?: string;
    //     property_id?: number
    // }

    // interface PropertyImagesInface {
    //     image?: string;
    //     property_id?: number
    // }

    interface RoomImagesInface {
        image?: string;
        room_id?: number;
    }
    const [rAmenities, setRAmenities] = useState<AmenitiesInface[]>([]);
    const [rHighAmenities, setRHighAmenities] = useState<any[]>([]);
    const [rDetails, setRDetails] = useState<RoomDetailsInface>({});
    // const [pImages, setPImages] = useState<PropertyImagesInface[]>([]);
    const [rImages, setRImages] = useState<RoomImagesInface[]>([]);
    const [address, setAddress] = useState("");
    const [rPolicy, setrPolicy] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [roomType, setRoomType] = useState("");
    const [number_of_guest, setNumberOfGuests] = useState("");
    const [remark, setRemark] = useState("");
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [openFocusIndex, setOpenFocusIndex] = useState(0);
    const [guestsInputValue, setGuestsInputValue] = useState<GuestsInput>({
        guestAdults: 1,
        guestChildren: 0,
        guestInfants: 0,
        petCount: 0,
    });
    let pet = 0;

    const [selectedDate, setSelectedDate] = useState<DateRage>({
        // startDate: moment(),
        startDate: null,
        // endDate: moment().add(1, "day"),
        endDate: null,
    });
    const formName = useRef(null);
    const formPhone = useRef(null);
    const formEmailo = useRef(null);
    const formAddess = useRef(null);
    const formremark =useRef(null);


    const onFinish = (e: any) => {
        e.preventDefault();
        // @ts-ignore
        formName.current.value = '';
        // @ts-ignore
        formPhone.current.value = '';
        // @ts-ignore
        formEmailo.current.value = '';
        // @ts-ignore
        formAddess.current.value = '';
        // @ts-ignore
        formremark.current.value = '';
        setLoading(true);

        const formData = new FormData();
        // console.log("Test..............", name, phone, email, address);

        const data : any = {
            name,
            phone,
            email,
            address,
            price,
            remark,
            checkin: selectedDate.startDate,
            checkout: selectedDate.endDate,
            Send: 1,
            room_type_id: id,
            number_of_guest: roomData[0].data.Properties.accommodates,
        };

        for (var key in data) {
            formData.append(key, data[key]);
        }

        setLoading(true);
        axios
            .post(`https://portal.safarihousehotel.com/api/?rf=reqsend`, formData)
            .then(function (response) {
                CISUI().notification("success", "Booking Request Sent Successfully");
                console.log("statusssss",response)
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
        // }
    };


    const [reservedDate, setReservedDate] = useState([]);
    const [roomData, setRoomData] = useState<any[]>([]);
    console.log("robin" + roomData);

    let obj = localStorage.getItem("user");
    // @ts-ignore
    let user = JSON.parse(obj);

    const PHOTOS: string[] = [];
    const { id } = useParams<{ id?: any }>();

    let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);
    let [isOpenModalAmenities2, setIsOpenModalAmenities2] = useState(false);
    const windowSize = useWindowSize();
    console.log();

    useEffect(() => {
        if (id !== undefined) {
            const selectedData = Data.find((item) => item?.id == id);
            setRoomData([selectedData]);
        }
    }, [id]);
    console.log("rHighAmenities=========", rHighAmenities);
    const bookRoom = () => {
        let header: any = "";
        if (user !== null) {
            header = CISUI().HeaderRequest;
        }
        setLoading(true);
        axios
            .post("https://portal.safarihousehotel.com/api/?rf=reqsend/${id}`")
            .then(function (response) {
                console.log(response);
                let totaldates = response.data.booked_date;
                let onlyDates: any = [];
                totaldates?.map((item: any) => {
                    onlyDates.push(moment(item.booked_date).format("MM/DD/YYYY"));
                });
                // console.log(onlyDates);
                setReservedDate(onlyDates);
                // console.log(response)
                // setAddress(atob(response.data.data.room_details.a));
                setrPolicy(atob(response.data.data.room_details.room_policy));
                // setName(atob(response.data.data.room_details.booking_policy))
                // setPhone(atob(response.data.data.room_details.cancellation_policy))
                setRAmenities(response.data.amenities);
                setRHighAmenities(response.data.highlighted_amenities);
                setRDetails(response.data.data.room_details);
                setRImages(response.data.data.room_images);
                setLoading(false);
            })
            .catch(function (error) {
                // console.log(error);
                setLoading(false);
            });
    };
    const dateRangeCheck = (date: any) => {
        // console.log(date);
        // console.log(date?.endDate);
        if (date?.endDate !== null) {
            const getDaysBetweenDates = function (startDate: any, endDate: any) {
                const now = startDate.clone(),
                    dates = [];

                while (now.isSameOrBefore(endDate)) {
                    dates.push(now.format("MM/DD/YYYY"));
                    now.add(1, "days");
                }
                return dates;
            };

            const startDate = selectedDate.startDate;
            const endDate = selectedDate.endDate;

            const dateList = getDaysBetweenDates(startDate, endDate);

            const intersection = reservedDate.filter((element) =>
                dateList.includes(element)
            );

            // console.log(intersection.length);
            if (intersection.length > 0) {
                // console.log("Match");
                setSelectedDate({
                    startDate: selectedDate.startDate,
                    endDate: null,
                });
                CISUI().notification("warning", "Invalid selection");
            }
        }
    };
    // var start_date = moment(selectedDate.startDate).format("MM-DD-YYYY");
    // var end_date = moment(selectedDate.endDate === null ? moment(selectedDate.startDate).add(1, "day")  : selectedDate.endDate).format("MM-DD-YYYY");
    // console.log(moment(selectedDate.endDate === null ? moment(selectedDate.startDate).add(1, "day")  : selectedDate.endDate).diff(moment(selectedDate.startDate),'days'))

    var nights = 1;
    var price = 0;
    const countNights = () => {
        // console.log("BeforeCountNights",nights)
        // var start_date = moment(selectedDate.startDate).format("MM-DD-YYYY");
        // var end_date = moment(selectedDate.endDate === null ? moment(selectedDate.startDate).add(1, "day")  : selectedDate.endDate).format("MM-DD-YYYY");
        // var date1 = new Date(start_date);
        // var date2 = new Date(end_date);
        // var Difference_In_Time = date2.getTime() - date1.getTime();
        // nights = Difference_In_Time / (1000 * 3600 * 24);
        nights = moment(
            selectedDate.endDate === null
                ? moment(selectedDate.startDate).add(1, "day")
                : selectedDate.endDate
        ).diff(moment(selectedDate.startDate), "days");
        console.log(selectedDate);
        // cleaning_price = (parseFloat(rDetails?.cleaning_fee as string));
        // price = parseFloat(rDetails?.customer_price as string)) * nights);
        price = nights * parseFloat(roomData[0]?.data?.Properties?.price);
        // discount = price * (parseFloat(rDetails.discount as string) / 100);
        // console.log(price);
        // console.log("AfterCountNights",nights)
    };
    useEffect(() => {
        // getRooms()
    }, []);

    let history = useHistory();
    const restrictedReserve = () => {
        if (selectedDate.endDate !== null) {
            let obj = localStorage.getItem("user");
            // @ts-ignore
            let user = JSON.parse(obj);
            // console.log(selectedDate);

            let startDate = moment(selectedDate.startDate).format("MM-DD-YYYY");
            let endDate = moment(selectedDate.endDate).format("MM-DD-YYYY");

            if (user === null) {
                CISUI().notification("warning", "Please Login First.");
                history.push("/login/customer");
            } else {
                // console.log(guestsInputValue);
                history.push(
                    `/checkout/${id}?sd=${startDate}&ed=${endDate}&ag=${guestsInputValue?.guestAdults}&cg=${guestsInputValue?.guestChildren}&ig=${guestsInputValue?.guestInfants}`
                );
            }
        } else {
            CISUI().notification("warning", "Please Select Check out date.");
        }
    };

    const getDaySize = () => {
        if (windowSize.width <= 375) {
            return 34;
        }
        if (windowSize.width <= 500) {
            return undefined;
        }
        if (windowSize.width <= 1280) {
            return 56;
        }
        return 48;
    };

    function closeModalAmenities() {
        setIsOpenModalAmenities(false);
    }

    function openModalAmenities() {
        setIsOpenModalAmenities(true);
    }
    function closeModalAmenities2() {
        setIsOpenModalAmenities2(false);
    }

    function openModalAmenities2() {
        setIsOpenModalAmenities2(true);
    }

    const handleOpenModal = (index: number) => {
        setIsOpen(true);
        setOpenFocusIndex(index);
    };

    const handleCloseModal = () => setIsOpen(false);
    const { t } = useTranslation();
    const renderSection1 = () => {
        return (
            <>
                <div className="listingSection__wrap !space-y-6">
                    {/* 1 */}
                    {/* <div className="flex justify-between items-center">
            <Badge name={rDetails?.room_type} />
          </div> */}

                    {/* 2 */}
                    <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
                        {t(roomData[0]?.data?.Properties?.name)}
                        <br />
                    </h2>

                    <div className="items-center hidden">
                        <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
                        <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
              <span className="text-neutral-900 dark:text-neutral-200 font-medium"></span>
            </span>
                    </div>

                    {/* 5 */}
                    <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

                    {/* 6 */}
                    <div className="flex items-center justify-between xl:justify-start space-x-6 xl:space-x-8 text-md text-neutral-700 dark:text-neutral-300">
                        {roomData[0]?.id !== undefined ? (
                            <div className="flex items-center space-x-3 ">
                                <i className=" las la-user-friends text-2xl mr-2"></i>
                                <span className=" sm:inline-block">
                  {" "}
                                    {t(roomData[0]?.data?.Properties?.accommodates)}
                </span>
                            </div>
                        ) : (
                            ""
                        )}

                        {roomData[0]?.id !== undefined ? (
                            <div className="flex items-center space-x-3 ">
                                <i className=" las la-bed text-2xl "></i>
                                <span className=" sm:inline-block">
                  {" "}
                                    {t(roomData[0]?.data?.Properties?.beds)}
                </span>
                            </div>
                        ) : (
                            ""
                        )}

                        {roomData[0]?.id !== undefined ? (
                            <div className="flex items-center space-x-3">
                                <i className="las la-ruler-horizontal text-2xl"></i>
                                <span className=" sm:inline-block">
                  {" "}
                                    {t(roomData[0]?.data?.Properties?.size)}
                </span>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </>
        );
    };

    const highAmenities = () => {
        return (
            <div className="listingSection__wrap">
                <div>
                    <h2 className="text-2xl font-semibold">
                        {localStorage.getItem("i18nextLng") === "ar"
                            ? "  وسائل الراحة المميزة"
                            : " Highlighted Amenities"}
                    </h2>
                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {localStorage.getItem("i18nextLng") === "ar"
                ? "  حول المرافق والخدمات المميزة في العقار"
                : "   About the property's highlighted amenities and services"}
          </span>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                {/* 6 */}
                <div className="text-sm text-neutral-700 dark:text-neutral-300 ">
                    {roomData[0]?.data?.Amenities.slice(0, 8).map((item: any) => (
                        <div key={item.name} className="inline-block my-4 mr-5">
                            <ButtonSecondary>
                                <span className="">{t(item.name)}</span>
                            </ButtonSecondary>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderSection2 = () => {
        return (
            <div className="listingSection__wrap">
                <h2 className="text-2xl font-semibold">
                    {localStorage.getItem("i18nextLng") === "ar"
                        ? " تفاصيل الغرفة  "
                        : " Room Details"}
                </h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                <div className="text-neutral-6000 dark:text-neutral-300">
                    <span>{t(roomData[0]?.data?.Properties?.details)}</span>
                </div>
            </div>
        );
    };

    const Amenities = () => {
        return (
            <div className="listingSection__wrap">
                <div>
                    <h2 className="text-2xl font-semibold">Amenities </h2>
                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            About the property's amenities and services
          </span>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                {/* 6 */}
                <div className="text-sm text-neutral-700 dark:text-neutral-300 ">
                    {rAmenities.slice(0, 8).map((item) => (
                        <div key={item?.name} className="inline-block my-4 mr-5">
                            <ButtonSecondary>
                                {/*<i className={`text-3xl las ${item.icon}`}></i>*/}
                                <span className="">{item?.name}</span>
                            </ButtonSecondary>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderMotalAmenities = () => {
        return (
            <Transition appear show={isOpenModalAmenities} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-50 overflow-y-auto"
                    onClose={closeModalAmenities}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
              &#8203;
            </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block py-8 h-screen w-full">
                                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                                    <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                                        <h3
                                            className="text-lg font-medium leading-6 text-gray-900"
                                            id="headlessui-dialog-title-70"
                                        >
                                            Amenities
                                        </h3>
                                        <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                                    </div>
                                    <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                                        {rAmenities.map((item) => (
                                            <div
                                                key={item.name}
                                                className="flex items-center py-6 space-x-8"
                                            >
                                                {/*<i*/}
                                                {/*    className={`text-4xl text-neutral-6000 las ${item.icon}`}*/}
                                                {/*></i>*/}
                                                <span>{item.name}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        );
    };

    const renderSection7 = () => {
        // let longitude;
        // if (typeof rDetails?.longitude === "string") {
        //     longitude = parseFloat(rDetails?.longitude);
        // }
        let lt = "";
        let lg = "";

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        rDetails?.latitude !== undefined ? (lt = rDetails?.latitude) : 0.0;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        rDetails?.longitude !== undefined ? (lg = rDetails?.longitude) : 0.0;
        return (
            <div className="listingSection__wrap">
                {/* HEADING */}
                <div>
                    <h2 className="text-2xl font-semibold">Location</h2>
                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {rDetails?.address}
          </span>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
                {/* MAP */}
                <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
                    <div className="rounded-xl overflow-hidden">
                        {lt !== "" && lg !== "" ? (
                            <GoogleMapReact
                                bootstrapURLKeys={{
                                    // key: "AIzaSyDxJaU8bLdx7sSJ8fcRdhYS1pLk8Jdvnx0",
                                    key: "AIzaSyB_T6uN1DqnL_Tp426Z-PSoVVsLzOaMEx8",
                                }}
                                defaultZoom={15}
                                yesIWantToUseGoogleMapApiInternals
                                defaultCenter={{
                                    lat: parseFloat(lt),
                                    lng: parseFloat(lg),
                                }}
                            >
                                <LocationMarker lat={parseFloat(lt)} lng={parseFloat(lg)} />
                            </GoogleMapReact>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const renderSidebar = () => {
        // @ts-ignore
        return (
            <div className="listingSection__wrap  p-7 ">
                {/* PRICE */}
                <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            KD {t(roomData[0]?.data?.Properties?.price)}
              <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /{" "}
                  {localStorage.getItem("i18nextLng") === "ar" ? "ليالي" : "nights"}
                  <br />
                  {/*{rDetails?.cleaning_fee? <>Cleaning fee: KD{rDetails?.cleaning_fee}/night</>:""}*/}
            </span>
          </span>
                    {/*<StartRating />*/}
                </div>

                {/* FORM */}
                <form
                    className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl "
                       onSubmit={onFinish}
                >
                    <StayDatesRangeInput
                        onClick={countNights()}
                        wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700"
                        onChange={(date) => {
                            setSelectedDate(date);
                            dateRangeCheck(date);
                        }}
                        numberOfMonths={1}
                        fieldClassName="p-5"
                        defaultValue={selectedDate}
                        anchorDirection={windowSize.width > 1400 ? "left" : "right"}
                        reservedDate={reservedDate}
                    />

                    {/*<hr className=" h-1 bg-gray-200 border-0 dark:bg-gray-700"/>*/}
                    <div className=" border-b border-neutral-200 dark:border-neutral-700"></div>

                    <div className="flex flex-col space-y-4 ml-3 mt-2 mr-3">

                        <div className="mb-5">
                            <label    htmlFor="name" className="mb-3 block text-base font-medium text-[#07074D]  dark:text-neutral-300">
                                {localStorage.getItem("i18nextLng") === "ar" ? "اسم" : "Name"}
                            </label>
                            <input
                                ref={formName}
                                type="text"
                                onChange={(e) => setName(e.target.value)}

                                id="name"
                                className="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-6
                                text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                                placeholder={t("Enter your name")}/>
                        </div>





                        <div className="mb-5">
                            <label    htmlFor="name" className="mb-3 block text-base font-medium text-[#07074D] dark:text-neutral-300">
                                {localStorage.getItem("i18nextLng") === "ar"
                                    ? "رقم الهاتف\n"
                                    : "Phone Number"}
                            </label>
                            <input
                                ref={formPhone}
                                type="text"
                                onChange={(e) => setPhone(e.target.value)}
                                id="name"
                                className="w-full rounded-md border border-[#e0e0e0] bg-white py-2  px-6
                                text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                                placeholder={t("Enter your number")}/>
                        </div>



                        <div className="mb-5">
                            <label    htmlFor="name" className="mb-3 block text-base font-medium text-[#07074D] dark:text-neutral-300">
                                {localStorage.getItem("i18nextLng") === "ar"
                                    ? "البريد الإلكتروني\n"
                                    : "Email"}
                            </label>
                            <input
                                ref={formEmailo}
                                type="text"
                                onChange={(e) => setEmail(e.target.value)}
                                id="name"
                                className="w-full rounded-md border border-[#e0e0e0] bg-white py-2  px-6
                                text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                                placeholder={t("you@example.com")}/>
                        </div>


                        <div className="mb-5">
                            <label    htmlFor="name" className="mb-3 block text-base font-medium text-[#07074D] dark:text-neutral-300">
                                {localStorage.getItem("i18nextLng") === "ar"
                                    ? "تبوك"
                                    : "Address"}
                            </label>
                            <textarea
                                ref={formAddess}
                                rows={2}
                                onChange={(e) => setAddress(e.target.value)}
                                id="name"
                                className="w-full rounded-md border border-[#e0e0e0] bg-white py-2  px-6
                                text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                                placeholder={t("Your address")}/>
                        </div>


                        <div className="mb-5">
                            <label    htmlFor="name" className="mb-3 block text-base font-medium text-[#07074D] dark:text-neutral-300">
                                {localStorage.getItem("i18nextLng") === "ar"
                                    ? "ملاحظة"
                                    : "Remark"}
                            </label>
                            <textarea
                                ref={formremark}
                                rows={2}
                                onChange={(e) => setRemark(e.target.value)}
                                id="name"
                                className="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-2  px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                                placeholder={t("Your remark")}/>
                        </div>





                        <div className="flex justify-between text-neutral-900 font-semibold dark:text-neutral-300 p-4">
              <span>
                KD {t(roomData[0]?.data?.Properties?.price)} x {nights || 0}{" "}
                  {localStorage.getItem("i18nextLng") === "ar"
                      ? "ليالي"
                      : "nights"}
              </span>
                            <span>KD {price || 0}</span>
                        </div>

             <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
             <div className="flex justify-between font-semibold p-5">
              <span>
                {" "}
                  {localStorage.getItem("i18nextLng") === "ar"
                      ? "المجموع"
                      : "Total"}
              </span>
               <span>KD {price} </span>
               </div>
           </div>

                    {/* SUBMIT */}
                    {/*<ButtonPrimary href={`/checkout/${id}`} type="button">Reserve</ButtonPrimary>*/}
                    {/*<ButtonPrimary onClick={restrictedReserve} type="button">Reserve</ButtonPrimary>*/}
                    <ButtonPrimary type="submit" className="mt-3 mb-2 ml-2 mr-2">
                        {localStorage.getItem("i18nextLng") === "ar"
                            ? "طلب الحجز\n"
                            : "Request to Book "}
                    </ButtonPrimary>
                </form>
            </div>
        );
    };

    // @ts-ignore
    return (
        // loading ? <Loading /> :
        <div
            className={`nc-ListingStayDetailPage  ${className}`}
            data-nc-id="PropertyDetailPage"
        >
            <Helmet>
                <title>{roomData[0]?.data?.Properties?.name || "Safari House"}</title>
            </Helmet>

            <main className="container mt-11  ">
                {/* CONTENT */}
                <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold ">
                    {t(roomData[0]?.data?.Properties?.name)}
                    <br />
                </h2>
                <div className=" my-10  text-center">
                    <div className="grid grid-rows-none md:grid-cols-3 py-4 gap-2 md:gap-4">
                        <img
                            className="w-full h-full object-cover  col-span-2 md:col-span-2 row-span-2"
                            src={`../assets/images/${roomData[0]?.data?.images[0]}`}
                            alt=""
                        />
                        {roomData[0]?.data?.images
                            ?.slice(1, 3)
                            ?.map((item: any, index: any | null | undefined) => (
                                <img
                                    className="w-full h-full object-cover "
                                    src={`../assets/images/${item}` || ""}
                                    alt=""
                                />
                            ))}
                    </div>
                </div>
                {/* CONTENT */}
                <div className="flex">
                    <div className="w-full lg:w-3/5 xl:w-3/5 space-y-8 lg:space-y-8  space__lang  ">
                        {renderSection1()}
                        <div className="md:hidden px-5 sm:px-10">{renderSidebar()}</div>
                        {roomData[0]?.data?.Amenities?.length !== 0 ? highAmenities() : ""}
                        {renderSection2()}
                        {rDetails?.latitude && rDetails?.longitude ? renderSection7() : ""}

                    </div>

                    <div className="hidden md:block flex-grow">
                        <div className="sticky top-24">{renderSidebar()}</div>
                    </div>
                </div>
            </main>



            {/* OTHER SECTION */}
            {!isPreviewMode && <div></div>}
        </div>
    );
};

export default PropertyDetailPage;
