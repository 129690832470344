// import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
// import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
// import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import React, {FC, useEffect, useState} from "react";
// import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
// import SectionHowItWork from "../../components/SectionHowItWork/SectionHowItWork";

// import HIW1img from "images/icons/HIW-1.png";
// import HIW2img from "images/icons/HIW-2.png";
// import HIW3img from "images/icons/HIW-3.png";
// import HIW1imgDark from "images/icons/HIW-1-dark.png";
// import HIW2imgDark from "images/icons/HIW-2-dark.png";
// import HIW3imgDark from "images/icons/HIW-3-dark.png";
// import Heading from "../../components/Heading/Heading";
import SectionOurFeatures from "../../components/SectionOurFeatures/SectionOurFeatures";
import rightImgPng from "../../images/Image templete-01.png";
import SectionDowloadApp from "../PageHome/SectionDowloadApp";
import imagePng2 from "images/APARTMENT.jpg";
import {heroInterface} from "../../components/SectionHero2/SectionHero2";
import axios from "axios";
import config from "../../utils/config";
// @ts-ignore
import DOMPurify from 'dompurify';
import Loading from "../../components/Loading/Loading";
export interface ListingStayPageProps {
    className?: string;
}

const Apartment: FC<ListingStayPageProps> = ({ className = "" }) => {
    const [loading, setLoading] = useState(false)
    const[hero,setHero]=useState<heroInterface>();
    useEffect(() => {
        heroAllData();
    }, []);

    const heroAllData = () =>{
        setLoading(true)
        axios.get(config.apiserver + 'widgets?page_name=Accommodation')
            .then(function (response) {
                const allData = response.data.data;
                console.log( "look " , response.data.data);
                setHero(allData[4]);
                setLoading(false)
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                setLoading(false)
            })
    }
    const createMarkup = (html: any) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }
    return (
        loading ? <Loading /> :
                    <div
                        className={`nc-ListingStayPage relative overflow-hidden ${className}`}
                        data-nc-id="Accommodation"
                    >
                        <Helmet>
                            <title>Easy 2 Manage (E2M) || Service Accommodation Specialist</title>
                        </Helmet>
                        <BgGlassmorphism />

                        <div className="container pt-10 pb-24 lg:pt-24 lg:pb-32">
                            <div>
                                <img src={imagePng2} alt="" className="rounded-3xl w-full sm:w-full md:w-full lg:w-3/6 xl:w-3/6 2xl:w-3/6 float-right ml-5 mb-5" />
                                <div>
                                    <h2 className="font-medium text-2xl md:text-3xl xl:text-4xl leading-[110%] mb-5">
                                        {hero?.name}
                                    </h2>
                                    <span className="text-justify md:text-lg text-neutral-500 dark:text-neutral-400" dangerouslySetInnerHTML={createMarkup(hero?.body)}>
                                </span>
                                        </div>
                                    </div>
                        </div>
                        <div className="container relative overflow-hidden">
                            <SectionDowloadApp />

                            {/* SECTION 1 */}
                            <div className="relative py-24">

                                <SectionSliderNewCategories
                                    // heading="Explore by types of stays"
                                    // subHeading="Explore houses based on 10 types of stays"
                                    // categoryCardType="card5"
                                    // itemPerRow={5}
                                    // sliderStyle="style2"
                                    // id='4'
                                />
                            </div>

                            {/* SECTION */}

                            {/* SECTION 6 */}
                            <SectionOurFeatures type="type2" rightImg={rightImgPng} />
                        </div>

                    </div>
    );
};

export default Apartment;
