import React, {FC, useEffect, useState} from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from "axios";
import config from "../../utils/config";
import {useParams} from "react-router-dom";
// @ts-ignore
import DOMPurify from 'dompurify';

export interface CitiesBlogProps {
	className?: string;
}
interface citiesDetailsProps {
	id?: any,
	title?: any,
	tags?: any,
	body?: any,
	image?: any,
	created_at?: any,
}
const CitiesBlog: FC<CitiesBlogProps> = ({ className = "" }) => {
	const { id } = useParams<{ id?: any }>();
	const [citiesDetails, setCitiesDetails] = useState<citiesDetailsProps>({});
	const getSingleCitiesBlogs = () => {
		axios.get(config.apiserver + `blogdetails/${id}`)
			.then(function (response) {
				console.log(response.data.data)
				setCitiesDetails(response.data.data)
			})
			.catch(function (error) {
				console.log(error);
			})
	}
	useEffect(() => {
		getSingleCitiesBlogs()
	}, [])
	const createMarkup = (html: any) => {
		return {
			__html: DOMPurify.sanitize(html)
		}
	}
	const tags = citiesDetails?.tags?.split(", ")
	return (
		<div className={`nc-PageAbout overflow-hidden relative ${className} mb-28`}>
			{/*<Helmet>*/}
			{/*	<title>About || Booking React Template</title>*/}
			{/*</Helmet>*/}

			{/*/!* ======== BG GLASS ======== *!/*/}
			{/*<BgGlassmorphism />*/}
			<div className="container py-16 lg:py-28 space-y-16 lg:space-y-28 my-10">
				<div>
					<img src={config.imageURL+'/public/upload/cms/blog/'+citiesDetails?.image} alt="" className="rounded-3xl w-full sm:w-full md:w-full lg:w-3/6 xl:w-3/6 2xl:w-3/6 float-right ml-5 mb-5" />
					<div>
						<h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100 mb-5">
							{citiesDetails?.title}
						</h2>
						<span className="my-8 text-justify md:text-lg text-neutral-500 dark:text-neutral-400" dangerouslySetInnerHTML={createMarkup(citiesDetails?.body)}>
							{/*{citiesDetails?.body}*/}
            </span>
					</div>
					<div className="max-w-screen-md mx-auto my-8 flex flex-wrap">


					{tags?.map((item: any) => (
						<a
							className="nc-Tag inline-block bg-white text-sm text-neutral-600 p-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
							href="##"
						>
							{item}
						</a>
					))}
					</div>

					{/*<div className="max-w-screen-md mx-auto my-8 flex flex-wrap">*/}
					{/*	<a*/}
					{/*		className="nc-Tag inline-block bg-white text-sm text-neutral-600 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"*/}
					{/*		href="##"*/}
					{/*	>*/}
					{/*		Garden*/}
					{/*	</a>*/}
					{/*	<a*/}
					{/*		className="nc-Tag inline-block bg-white text-sm text-neutral-600 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"*/}
					{/*		href="##"*/}
					{/*	>*/}
					{/*		Jewelry*/}
					{/*	</a>*/}
					{/*	<a*/}
					{/*		className="nc-Tag inline-block bg-white text-sm text-neutral-600 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"*/}
					{/*		href="##"*/}
					{/*	>*/}
					{/*		Tools*/}
					{/*	</a>*/}
					{/*</div>*/}
					<ButtonPrimary href="/contact" >Contact Us!</ButtonPrimary>
				</div>
			</div>

		</div>
	);
};

export default CitiesBlog;
