import React, {FC, useState} from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import {Helmet} from "react-helmet";
import Input from "shared/Input/Input";
import {Link, useParams, useHistory, Redirect} from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";

// import match from 'react-dom';
// import {AnyReactComponentProps} from "../../components/AnyReactComponent/AnyReactComponent";
import Config from '../../utils/config';
import axios from 'axios';
// import config from "../../utils/config";
import {CISUI} from "../../utils/CISUI";
import config from "../../utils/config";

export interface PageLoginProps {
    className?: string;
    id?: any;

}

const loginSocials = [
    {
        name: "Continue with Facebook",
        href: "#",
        icon: facebookSvg,
    },
    {
        name: "Continue with Twitter",
        href: "#",
        icon: twitterSvg,
    },
    {
        name: "Continue with Google",
        href: "#",
        icon: googleSvg,
    },
];

// eslint-disable-next-line react-hooks/rules-of-hooks
const OTPVerify: FC<PageLoginProps> = ({className = "", id = useParams()}) => {
    let type = id.id;


    let history = useHistory();

    let urlParam = new URLSearchParams(window.location.search);
    let is_verified = urlParam.get('verified');
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const phone = urlParams.get('phone')
    console.log(phone)
    const [state, setState] = useState({
        otp:''
    });
    const [errorMsg, setErrorMsg] = useState("");
    // const history = useH
    const [visiable, setvisiable] = useState(0);
    const handleSubmit = (e: any) => {
        e.preventDefault();
        console.log("+"+phone?.trim())
        const userData = {
            phone:"+"+phone?.trim(),
            code:state.otp
        }
        axios.post(config.apiserver +`verifyphone`,userData)
            .then((res: { data: any; }) => {
                // console.log(res.data)
                console.log(type)
                if (res.data.status === 1) {
                    history.push('/login/customer')
                    CISUI().notification('success', res.data.msg);
                } else {
                    if(res.data.msg!==undefined){
                        setErrorMsg(res.data.msg)
                    }
                    else {
                        CISUI().notification('error', res.data.msg);
                    }
                }
            })
            .catch(error => {
                console.log(error);
                CISUI().notification('error', "Error");
            })
    }
    const changeInput = (e: any) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    };
    return (
        localStorage.getItem('access_token') ?
            <Redirect to='/customer/customer-upcoming-booking'/>
            :
            <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
                <Helmet>
                    <title>Easy 2 Manage (E2M) || The Property Management Company</title>
                </Helmet>
                <div className="container mb-24 lg:mb-32">
                    <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                        OTP Verification
                    </h2>
                    <div className="max-w-md mx-auto space-y-6">
                        {
                            errorMsg !== '' && (
                                <p className="login_error" style={{textAlign: "center", fontSize:20 ,color: "red"}}>{errorMsg}</p>
                            )
                        }
                        {
                            is_verified && (
                                <p className="login_error" style={{textAlign: "center", fontSize:20 ,color: "red"}}>Your account is verified. Now you can login.</p>
                            )
                        }
                        <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
                            <label className="block">
                                <span className="text-neutral-800 dark:text-neutral-200">
                                    OTP code
                                </span>
                                <Input
                                    name="otp" type="text" placeholder="Enter the otp code" value={state.otp} onChange={(e) => changeInput(e)} />
                                {/*{*/}
                                {/*    errors &&*/}
                                {/*    <p style={{ color: "red" }}>{errors.phone}</p>*/}
                                {/*}*/}
                            </label>
                            <ButtonPrimary type="submit">Submit</ButtonPrimary>
                        </form>
                    </div>
                </div>
            </div>
    );
};

export default OTPVerify;