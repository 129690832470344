import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionBecomeAnAuthor from "../../components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import GoogleMapReact from "google-map-react";
import LocationMarker from "../../components/AnyReactComponent/LocationMarker";
import Select from "shared/Select/Select";
import axios from "axios";
import config from "utils/config";
import { CISUI } from "utils/CISUI";
import ReCAPTCHA from "react-google-recaptcha";

export interface PageContactProps {
    className?: string;
}

const info = [
    {
        title: "🗺 ADDRESS",
        desc: "65 St Pauls Avenue Slough, SL2 5EX",
    },
    {
        title: "💌 EMAIL",
        desc: "info@easy2manage.co.uk",
    },
    {
        title: "☎ PHONE",
        desc: "03332423309",
    },
];

const Complaint: FC<PageContactProps> = ({ className = "" }) => {
    const [flag, setflag] = useState(true)
    // const [errorMsg, setErrorMsg] = useState("");
    const [reCaptcha, setReCaptcha] = useState<string | null | boolean>(false)
    const [state, setState] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        booking_ref: '',
        reason: '',
        message: '',
    });
    const [errors, setErrors] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        booking_ref: '',
        reason: '',
        message: '',
    })


    const handleSubmit = (e: any) => {
        e.preventDefault();

        if (reCaptcha === false) {
            setReCaptcha(null)
        } else {

            const data = {
                first_name: state.first_name,
                last_name: state.last_name,
                phone: state.phone,
                email: state.email,
                booking_ref: state.booking_ref,
                reason: state.reason,
                message: state.message
            }

            console.log(data)


            axios.post(config.apiserver + `complained`, data)
                .then(res => {
                    // console.log(res.data)

                    if (res.data.status === 1) {
                        // CISUI().notification('success', res.data.msg);
                        setState({
                            first_name: '',
                            last_name: '',
                            email: '',
                            phone: '',
                            booking_ref: '',
                            reason: '',
                            message: ''
                        })

                        setErrors({
                            first_name: '',
                            last_name: '',
                            email: '',
                            phone: '',
                            booking_ref: '',
                            reason: '',
                            message: ''
                        })
                        setflag(false)
                        CISUI().notification('success', res.data.msg)
                    }
                    // console.log(type)
                    else {
                        // setErrorMsg(res.data.msg)
                        CISUI().notification('error', res.data.msg)
                        // setflag(true)
                    }
                })
                .catch(error => {
                    // console.log(error.response.data.errors)
                    // setflag(false)
                    setErrors(error.response.data.errors);
                })
        }



    }

    function onChange(value: any) {
        console.log("Captcha value:", value);
        setReCaptcha(value)
    }

    const changeInput = (e: any) => {
        // console.log(e.target.value)
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    };
    return (
        <div
            className={`nc-PageContact overflow-hidden ${className}`}
            data-nc-id="PageContact"
        >
            <Helmet>
                <title>Easy 2 Manage (E2M) || The Property Management Company</title>
            </Helmet>
            <div className="mb-10 lg:mb-32">
                <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    Complaint Form
                </h2>
                <div className="container max-w-7xl mx-auto">
                    <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
                        <div className="items-center max-w-sm space-y-8 mt-14">
                            {info.map((item, index) => (
                                <div key={index}>
                                    <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                                        {item.title}
                                    </h3>
                                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                                        {item.desc}
                                    </span>
                                </div>
                            ))}
                            <div>
                                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                                    🌏 SOCIALS
                                </h3>
                                <SocialsList className="mt-2" />
                            </div>
                        </div>
                        <div>
                            {flag ? (
                                <form className="grid grid-cols-1 gap-6" action="#" method="post" onSubmit={handleSubmit}>
                                    <div className="grid grid-cols-2 gap-6">
                                        <label className="block">
                                            <Label>First Name <sup style={{ color: "red", fontSize: "15px" }}>*</sup></Label>

                                            <Input
                                                placeholder="First Name"
                                                type="text"
                                                className="mt-1"
                                                name="first_name"
                                                value={state.first_name}
                                                onChange={(e) => changeInput(e)}
                                            />
                                            {
                                                errors &&
                                                <p style={{ color: "red", fontSize: "15px" }}>{errors.first_name}</p>
                                            }
                                        </label>
                                        <label className="block">
                                            <Label>Last Name <sup style={{ color: "red", fontSize: "15px" }}>*</sup></Label>

                                            <Input
                                                placeholder="Last Name"
                                                type="text"
                                                className="mt-1"
                                                name="last_name"
                                                value={state.first_name}
                                                onChange={(e) => changeInput(e)}

                                            />
                                            {
                                                errors &&
                                                <p style={{ color: "red", fontSize: "15px" }}>{errors.last_name}</p>
                                            }
                                        </label>
                                        <label className="block">
                                            <Label>Email address <sup style={{ color: "red", fontSize: "15px" }}>*</sup></Label>
                                            <Input
                                                type="email"
                                                placeholder="example@example.com"
                                                className="mt-1"
                                                name="email"
                                                value={state.email}
                                                onChange={(e) => changeInput(e)}
                                            />
                                            {
                                                errors &&
                                                <p style={{ color: "red", fontSize: "15px" }}>{errors.email}</p>
                                            }
                                        </label>
                                        <label className="block">
                                            <Label>Phone Number <sup style={{ color: "red", fontSize: "15px" }}>*</sup></Label>
                                            <Input
                                                placeholder="Phone Number"
                                                type="number"
                                                className="mt-1"
                                                name="phone"
                                                value={state.phone}
                                                onChange={(e) => changeInput(e)}
                                            />
                                            {
                                                errors &&
                                                <p style={{ color: "red", fontSize: "15px" }}>{errors.phone}</p>
                                            }
                                        </label>

                                        <label className="block">
                                            <Label>Booking Ref <sup style={{ color: "red", fontSize: "15px" }}>*</sup></Label>

                                            <Input
                                                placeholder="Booking Ref"
                                                type="text"
                                                className="mt-1"
                                                name="booking_ref"
                                                value={state.booking_ref}
                                                onChange={(e) => changeInput(e)}
                                            />
                                            {
                                                errors &&
                                                <p style={{ color: "red", fontSize: "15px" }}>{errors.booking_ref}</p>
                                            }
                                        </label>
                                        <label className="block">
                                            <Label>Complaint Reason <sup style={{ color: "red", fontSize: "15px" }}>*</sup></Label>
                                            <Select name="reason" value={state.reason}
                                                onChange={(e) => changeInput(e)}>
                                                <option value="">Select a option</option>
                                                <option value="Customer Service">Customer Service</option>
                                                <option value="Cleaning">Cleaning</option>
                                                <option value="Property Details">Property Details</option>
                                            </Select>
                                            {
                                                errors &&
                                                <p style={{ color: "red", fontSize: "15px" }}>{errors.reason}</p>
                                            }
                                        </label>
                                    </div>
                                    <label className="block">
                                        <Label>Complaint <sup style={{ color: "red", fontSize: "15px" }}>*</sup></Label>

                                        <Textarea className="mt-1" rows={6} placeholder="Complaint..." name="message"
                                            value={state.message}
                                            onChange={(e) => changeInput(e)} />
                                        {
                                            errors &&
                                            <p style={{ color: "red", fontSize: "15px" }}>{errors.message}</p>
                                        }
                                    </label>

                                    <ReCAPTCHA
                                        sitekey="6LcYrgoeAAAAALhdWsL9zj19peuJ61rtK1xCn-K6"
                                        onChange={onChange}

                                    />

                                    {
                                        reCaptcha === null &&
                                        <p style={{ color: "red", fontSize: "11px" }}>Please verify that you are not a robot.</p>
                                    }
                                    <div>
                                        <ButtonPrimary type="submit" className="w-full">Send</ButtonPrimary>
                                    </div>
                                </form>
                            ) : (
                                <div className="py-20">
                                    <p className="font-medium text-neutral-700 dark:text-neutral-300 text-base">We are sorry, you had a complaint with one of our services but rest assure, we have received your complaint and we will look into this and will get in touch within next 2 working days.</p>
                                    <p className="text-base font-medium text-neutral-700 dark:text-neutral-300 mt-2">If anything urgent please call us at 0333 242 3309</p>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Complaint;
