import React, {useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import Badge from "shared/Badge/Badge";
import axios from "axios";
import "../../../src/data/jsons/room_types.json"
// import config from "utils/config";
import { CISUI } from "../../utils/CISUI";
import config from "utils/config";


const PropertyCardH = (): JSX.Element => {

  interface RoomInface{
    id : number;
    property_name:string;
    bed:number;
    size:number;
    name:string;
    details:string;
    balcony:number;
    bathroom:number;
    facilities:string[];
    kitchen:number;
    other_room:number;
    price:number;
    room_type:string;
  }


  const [rooms, setRooms] = useState<RoomInface[]>([])

  const getRooms = (() => {

    axios.get(config.apiserver + 'property/propertyinfos', CISUI().HeaderRequest).then(function (response) {
        // handle success
        console.log("response");
        console.log(response.data.data);
        setRooms(response.data.data)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  })
  useEffect(() => {
    getRooms()
  }, [])
  return (
    <>
      {rooms.map((item, index) => (

        <div
          className={`nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow h-full`}
          data-nc-id="PropertyCardH"
          id={item.room_type}
          key={item.id}
        >
          <Link
            to={"/"}
            className="h-full w-full flex flex-col sm:flex-row sm:items-center"
          >
            <div className="flex-shrink-0 p-3 w-full h-full sm:w-64 ">
              {/* <GallerySlider
            ratioClass="aspect-w-1 aspect-h-1"
            galleryImgs={}
            className="w-full h-full rounded-2xl overflow-hidden"
            uniqueID={`PropertyCardH-${Date.now()}-${id}`}
          /> */}
              {/*
          {saleOff && (
            <SaleOffBadge className="absolute left-5 top-5 !bg-orange-500" />
          )} */}
            </div>
            <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
              <div className="space-y-4 w-full">
                {/* <div className="inline-flex space-x-3">
                  <Badge
                    name={
                      <div className="flex items-center">
                        <i className="text-sm las la-share-alt"></i>
                        <span className="ml-1">4 Network</span>
                      </div>
                    }
                  />
                  <Badge
                    name={
                      <div className="flex items-center">
                        <i className="text-sm las la-user-friends"></i>
                        <span className="ml-1">Family</span>
                      </div>
                    }
                    color="yellow"
                  />
                </div> */}
                <div className="flex items-center space-x-2">
                  <Badge name="ADS" color="green" />
                  <h2 className="text-lg font-medium capitalize">
                    {/*<span className="line-clamp-2">{item.property_name}</span>*/}
                  </h2>
                </div>
                <div className="inline-grid grid-cols-3 gap-2 flex table flex-col">
                  {/*<div className="flex items-center space-x-2 table-cell align-middle">*/}
                  {/*  <span className="hidden sm:inline-block">*/}
                  {/*    <i className="las la-bed text-lg"></i>*/}
                  {/*  </span>*/}
                  {/*</div>*/}
                  {/*<div>*/}
                  {/*  /!*<span className="text-xs text-neutral-500 dark:text-neutral-400">*!/*/}
                  {/*    {item.bed} bed*/}
                  {/*  /!*</span>*!/*/}

                  {/*</div>*/}

                  {/* ---- */}
                  <div className="flex items-center space-x-2">
                    <span className="hidden sm:inline-block">
                      <i className="las la-bath text-lg"></i>
                    </span>
                    <span className="text-xs text-neutral-500 dark:text-neutral-400">
                      {item.bathroom} baths
                    </span>
                  </div>

                  {/* ---- */}
                  <div className="flex items-center space-x-2">
                    <span className="hidden sm:inline-block">
                      <i className="las la-expand-arrows-alt text-lg"></i>
                    </span>
                    <span className="text-xs text-neutral-500 dark:text-neutral-400">
                      {item.size} Sq. Fit
                    </span>
                  </div>
                </div>
                <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 "></div>
                <div className="flex w-full justify-between items-end">
            {/* <StartRating reviewCount={reviewCount} point={reviewStart} /> */}
            <span className="flex items-center justify-center px-3 py-2 border border-secondary-500 rounded leading-none text-base font-medium text-secondary-500">
            KD{item.price}
            </span>
          </div>
              </div>
            </div>
          </Link>
          <BtnLikeIcon
            colorClass={`bg-neutral-100 dark:bg-neutral-700 hover:bg-neutral-200 hover:bg-opacity-70 text-neutral-6000 dark:text-neutral-400`}
            isLiked={false}
            className="absolute right-5 top-5 sm:right-3 sm:top-3 "
          />
        </div>
      ))}
    </>
  );
};


export default PropertyCardH;
