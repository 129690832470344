import React, {FC, useEffect, useState} from "react";
import { Helmet } from "react-helmet";
import LeftNav from "../../components/Portal/LeftNav";
import avaterPic from "images/avatars/avater.png";
import ListView from "../../components/ListView/ListView";
import axios from "axios";
import config from "../../utils/config";
import {CISUI} from "../../utils/CISUI";
import Loading from "../../components/Loading/Loading";
export interface PaymentHistoryProps {
	className?: string;
}
interface UserInfoInface {
	firstname?:any,
	lastname?:any,
	phone?:any,
	email?: any,
	address?:any,
	country?:any,
	city?:any,
	password?:any,
	confirmPassword?:any,
	picture?:any,
	errors?: {},
	current_password?:any,
}

const CustomerPaymentHistory: FC<PaymentHistoryProps> = ({ className = "" }) => {
	const [user, setUser] = useState<UserInfoInface>({});
	const [loading, setLoading] = useState(false)
	const getUserInfo = (() => {
		setLoading(true)
		axios.get(config.apiserver + "myaccount", CISUI().HeaderRequest)
			.then(res => {
				// console.log(res.data.data)
				// setState(res.data.data)
				setUser({
					firstname:res.data.data.firstname,
					lastname: res.data.data.lastname,
					phone:res.data.data.phone,
					email: res.data.data.email,
					address:res.data.data.address,
					country:res.data.data.country,
					city:res.data.data.city,
					picture:res.data.data.image,
				})
				setLoading(false)
			})
			.catch(error => {
				console.log(error);
				CISUI().notification('error', error);
				setLoading(false)
			})
	})
	useEffect(() => {
		getUserInfo()
	}, [])
	return (
		<div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
			<Helmet>
				<title>Easy 2 Manage (E2M) || The Property Management Company</title>
			</Helmet>
			{loading ? <Loading/> :(
				<main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
					<LeftNav
						profile={user.picture? user.picture:avaterPic}
						username= {user?.firstname+" "+ user?.lastname}
						designation='Customer'
						type='customer'
					/>
					<div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
						<ListView
							pageTitle='Payment History'
							desc=''
							show='paymentHistory'/>
					</div>
				</main>
				)}

		</div>
	);
};

export default CustomerPaymentHistory;
