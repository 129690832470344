import { FC, useState } from "react";
import HomeCompareInternational from "./HomeCompareIntl/HomeCompareInternational";
import HomeFaq from "./HomeFaq";
import HomeIntro from "./HomeIntro";
import RoomIntro from "./RoomIntroSlider/RoomIntro";
import HomeSlider from "../../containers/PageHome/HomeSlider";
import FindLocation from "./FindLocation";


// @ts-ignore
const PageHome: FC = () => {

  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  return  (
    <div>
      <div className="container">
        <HomeSlider />
        <HomeIntro />
        <RoomIntro />
        <HomeCompareInternational />
        <HomeFaq />
        <FindLocation />
      </div>
    </div>
  );
};

export default PageHome;
