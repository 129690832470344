import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import axios from "axios";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import config from "utils/config";
// @ts-ignore
import DOMPurify from "dompurify";
import Loading from "../../components/Loading/Loading";
//@ts-ignore

export interface locationArea {
  image: string;
  name: string;
  details: string;
  ar_details: string;
  ar_subtitle: string;
  subtitle: string;
  title: string;
  ar_title: string;
  keyword: string;
  id: any;
}
export interface AllPage {
  className?: string;
}
// const marked = window.marked;
declare global {
  interface Window {
    marked: any;
  }
}

const FindLocation: FC<AllPage> = ({ className = "" }) => {
  let marked = window.marked; // ok now
  const [locationArea, setLocationArea] = useState<locationArea>();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const sectionHomeLocation = () => {
    setLoading(true);
    axios
      .get(config.apiserver + "widgets?page_name=Location_Section")
      .then(function (response) {
        const locationArea = response.data.data.filter(
          (item: any) => item?.keyword === "location"
        );
        setLocationArea(locationArea[0]);
        // console.log("location", locationArea);
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    sectionHomeLocation();
  }, []);

  const createMarkup = (html: any) => {
    return {
      __html: marked.parse(DOMPurify.sanitize(html)),
    };
  };

  return  loading ? <Loading height={700} />: (
    <>
      <div className="location__intro p__50 ">
        <div className="left__intro ">
          <div
            className="title__intro text-3xl font-semibold text-neutral-900 md:text-3xl
         xl:text-3xl dark:text-neutral-100 tracking-wide"
          >
            <span
              dangerouslySetInnerHTML={createMarkup(
                localStorage.getItem("i18nextLng") === "ar"
                  ? locationArea?.ar_title
                  : locationArea?.title
              )}
            ></span>
            {/* {locationArea?.title} */}
          </div>
          <div className="detail__intro text-justify">
            <p>
              <span
                  dangerouslySetInnerHTML={createMarkup(localStorage.getItem("i18nextLng") === "ar"
                      ? locationArea?.ar_details.replace(/(<([^>]+)>)/gi, "")
                      :locationArea?.details.replace(/(<([^>]+)>)/gi, "")
                  )}
              />
            </p>
          </div>
        </div>
        <div className="right__intro__location">
          <a
            href="https://goo.gl/maps/zy7LZRenon2hqfoBA"
            target="_blank"
            rel="noopener noreferrer"
          >

            <img
              src={config.imageURL + "public/" + locationArea?.image}
              alt=""
              className="w-full"
            />
          </a>
        </div>
      </div>
    </>
  );
};

export default FindLocation;
