// import React, { FC, useEffect } from "react";
// import { Tab } from "@headlessui/react";
import { useEffect, useState } from "react";
import Label from "../../components/Label/Label";
import Input from "../../shared/Input/Input";
import ButtonPrimary from "../../shared/Button/ButtonPrimary";
// import avaterPic from "../../images/avatars/avater.png";
import axios from "axios";
// import config from "../../utils/config";
import Config from "../../utils/config";
import { CISUI } from "../../utils/CISUI";
import { useHistory } from "react-router-dom";
import config from "../../utils/config";
import Select from "shared/Select/Select";
// import Loading from "../../components/Loading/Loading";

interface UserInfoInface {
    name?: any,
    country?: any,
    nid_no?: any,
    date_of_birth?: any,
}

const NidVerification = () => {
    const [state, setState] = useState<UserInfoInface>({});
    // const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({
        name: '',
        country: '',
        nid_no: '',
        date_of_birth: ''
    })

    const [eyeToggle, setEyeToggle] = useState(false)
    let history = useHistory();

    // const customer: any = localStorage.getItem('user')
    // const user = JSON.parse(customer)
    // const id = user.id;

    const changeInput = (e: any) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    };


    const handlePasswordSubmit = (e: any) => {

        e.preventDefault();

        setLoading(true)

        setErrors({
            name: '',
            country: '',
            nid_no: '',
            date_of_birth: ''
        })
            // console.log("match");
            let data = {
                name: state.name,
                nid_no: state.nid_no,
                date_of_birth: state.date_of_birth,
                country: state.country
            }
            console.log(data);


            axios.post(Config.apiserver + `passwordreset`, data).then((res) => {
                console.log(res);
                setLoading(false)
                // history.push('/login/customer');
                if (res.data.status === 1) {
                    CISUI().notification('success', res.data.msg);
                    history.push('/login/customer');
                } else {
                    CISUI().notification('error', res.data.msg);
                }

            }).catch(error => {
                console.log(error);
                setErrors(error.response.data.errors);
                setLoading(false)
                CISUI().notification('error', error);
                // setLoading(false)
            })



    }


    interface CounrtyInface {
        name?: string;
    }
    const [country, setCountry] = useState<CounrtyInface[]>([]);
    const getCountry = (() => {
        axios.get(config.apiserver + "country")
            .then(function (response) {
                console.log("response")
                console.log(response)
                setCountry(response.data.data)
            })
            .catch(function (error) {
                console.log(error);
            })
    })


    useEffect(() => {
        getCountry()
    }, [])

    return (
        <div className="flex items-center justify-center mb-10">

            <form className="max-w-xl space-y-6 w-full" onSubmit={handlePasswordSubmit}>
                <h2 className="my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">NID Verification</h2>
                <div>
                    <Label>Your name <sup style={{ color: "red", fontSize: "15px" }}>*</sup></Label>
                    <Input name="name" type="text" className="mt-1.5"
                        value={state.name} onChange={(e) => changeInput(e)} placeholder="Your Name" />
                    {
                        errors &&
                        <p style={{ color: "red" }}>{errors.name}</p>
                    }
                </div>
                <div>
                    <Label>Nid number <sup style={{ color: "red", fontSize: "15px" }}>*</sup></Label>
                    <Input name="nid_no" type="text" className="mt-1.5"
                        value={state.nid_no} onChange={(e) => changeInput(e)} placeholder="Nid Number" />
                    {
                        errors &&
                        <p style={{ color: "red" }}>{errors.nid_no}</p>
                    }
                </div>


                <div>
                    <Label> Date Of Birth <sup style={{ color: "red", fontSize: "15px" }}>*</sup></Label>
                    <Input name="date_of_birth" type="date" className="mt-1.5"
                        value={state.date_of_birth} onChange={(e) => changeInput(e)} placeholder="Date of Birth" />
                    {
                        errors &&
                        <p style={{ color: "red" }}>{errors.date_of_birth}</p>
                    }


                </div>

                <div>
                    <Label> Select Country <sup style={{ color: "red", fontSize: "15px" }}>*</sup></Label>
                    <Select className="mt-1.5">
                        <option value="">Select an option</option>
                        {
                            country.map((item, index) =>
                                <option key={index}>{item.name}</option>
                            )
                        }
                    </Select>



                    {
                        errors &&
                        <p style={{ color: "red" }}>{errors.country}</p>
                    }
                </div>



                <div className="pt-2">
                    <ButtonPrimary loading={loading}>Verified</ButtonPrimary>
                </div>
            </form>
        </div>
    )
}

export default NidVerification;
