import ModalPhotos from "containers/ListingDetailPage/ModalPhotos";
import {FC, useEffect, useState} from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import Heading from "../../components/Heading/Heading";
import SectionVideos from "../PageHome/SectionVideos";
import {useTranslation} from "react-i18next";
import axios from "axios";
import config from "../../utils/config";
import "App.css"
import {internationalArea} from "../PageHome/HomeCompareIntl/HomeCompareInternational";
import VideoGallery from "../PageHome/VideoGallery";
import Loading from "../../components/Loading/Loading";

export interface ListingStayDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
  heading?: string;
  subHeading?: string;
  sliderStyle?: "style1" | "style2";
}

export interface galleryArea {
  image: string;
  name: string;
  body: string;
  title: string;
  subtitle: string;
  keyword: string;
  id: any;
}


// const PHOTOS: string[] = [
//   "https://cf.bstatic.com/xdata/images/hotel/max1024x768/390188831.jpg?k=f0ac76e3a100f44aab3bfd36b67791a0f6764e3d2ec1731d9a76e98d276680e7&o=&hp=1",
//   "https://cf.bstatic.com/xdata/images/hotel/max1024x768/390197897.jpg?k=84b62a1512d698fac2f395c52b23b5ad49739cc7efc5af183b7aa3c764f79a32&o=&hp=1",
//   "https://cf.bstatic.com/xdata/images/hotel/max1024x768/403060124.jpg?k=d68cc5b59425d4b7dcc8c819fb9231ee06ddedc352932aa800decd3d8e62934f&o=&hp=1",
//   "https://cf.bstatic.com/xdata/images/hotel/max1024x768/403060684.jpg?k=11f6840d1df0d874c2683d86702f1ec286842e6aec679349d5bc1bcf6f81e126&o=&hp=1",
//   "https://cf.bstatic.com/xdata/images/landmark/max1024/184153.webp?k=0bfe08b8b60dc7ff483108993520cb28d712675cbe2477458dce1abb8c85e40b&o=",
//   "https://cf.bstatic.com/xdata/images/hotel/max1024x768/403222383.jpg?k=18f0ce69b9626e287bd94b8be1d3ecc2e07a0f7c7156b1a43a620af50ee943f3&o=&hp=1",
//   "https://cf.bstatic.com/xdata/images/hotel/max1024x768/390186939.jpg?k=13baadb4b176178087890b97c920d86248f55ac4e6fdf0c7132d880e6171364e&o=&hp=1",
// ];

const Gallery: FC<ListingStayDetailPageProps> = ({
  className = "",
  isPreviewMode,
  heading = "Indoor Pictures",
  // subHeading = "Descriptions for sections",
  sliderStyle = "style1",
}) => {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [gallery, setGallery] =
      useState<galleryArea[]>();
  const [PHOTOS, setPhotos] = useState<any[]>([])

  const sectionInternational = () => {
    setLoading(true);
    axios
        .get(config.apiserver + "widgets?page_name=Gallery_page")
        .then(function (response) {
          const allData = response.data.data;
          const galleryData = allData.filter(
              (item: any) => item?.keyword === "gallery_image"
          );

          const photosData = galleryData.map((item:any) => `${config.imageURL}public/${item?.image}`)
          setPhotos(photosData);
          // const interRoomData = response.data.data.filter((item:any) => item?.keyword === "internatioin_key_info");
          // const interRoomData = response.data.data.filter(
          //     (item: any) => item?.keyword === "internatioin_key_info"
          // );
          // setGallery(interRoomData);
          // console.log("image", interRoomData?.image);
          // console.log("inter===", interRoomData);
          console.log("gallery===+", galleryData)
          setLoading(false);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          setLoading(false);
        });
  };
  useEffect(() => {
    sectionInternational();
  }, []);

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };



  const handleCloseModal = () => setIsOpen(false);

  return  loading ? <Loading height={400} /> : (
    <div
      className={`nc-ListingStayDetailPage  ${className}`}
      data-nc-id="PropertyDetailPage"
    >
      <Helmet>
        <title>Safari House || Gallery</title>
      </Helmet>

      <section>
        <header className="container   mt-10">
          <Heading
            className="mt-2 mb-6"
            // desc={subHeading}
            isCenter={sliderStyle === "style2"}
          >
            {t(heading = "Indoor Pictures")}
          </Heading>

          <div className="relative gallery__container">

            {PHOTOS?.slice(0, 5).map((item, index) => (
              <div
                key={index}
                className={`relative overflow-hidden gallery__grid-item${index+1}
                }`}
              >
                <NcImage key={item}
                  containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                  className="object-cover w-full h-full"
                  src={item || ""}
                  prevImageHorizontal
                />

                {/* OVERLAY */}
                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={() => handleOpenModal(index)}
                />
              </div>
            ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10 scale-75"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
            </div>
          </div>
        </header>
        <ModalPhotos
          imgs={PHOTOS}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
        />
      </section>

      {!isPreviewMode && (
        <div className="container my-20">
          <SectionVideos className="mb-4 md:mb-2" />
        </div>
      )}
      <>
      {/*<VideoGallery/>*/}
      </>
    </div>
  );
};

export default Gallery;
