import {toast} from "react-toastify";

const CISUI = () => {

    const LocalStorage = () => {

    }

    const HeaderRequest = () => {
        const token = localStorage.getItem('access_token');
        return {headers : {'Authorization' : `${token}`}};
    };

    const createNotification = (type: string, message: string) => {
            let notify: string = '';
            switch (type) {
                case 'info':
                    toast.info(message);
                    break;
                case 'success':
                    toast.success(message)
                    break;
                case 'warning':
                    toast.warning(message)
                    break;
                case 'error':
                    toast.error(message)
                    break;
                default:
                    toast.success(message)
                    break;
            }
        return notify;
    };

    return {
        HeaderRequest : HeaderRequest(),
        LocalStorage: LocalStorage(),
        notification: createNotification
    }
}

export {CISUI}