const AnimateButton = (props: any) => {
  return (
    <div>
      <a href={props.link} className="animated-button1">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        {props.btnTitle}
      </a>
    </div>
  );
};

export default AnimateButton;
