import { Disclosure } from "@headlessui/react";
import { useTranslation } from "react-i18next";

import axios from "axios";
import { FC, useEffect, useState } from "react";
import { HiChevronDown } from "react-icons/hi";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import config from "utils/config";
// @ts-ignore
import DOMPurify from "dompurify";
import Loading from "../../components/Loading/Loading";
//@ts-ignore
export interface faqArea {
  image: string;
  name: string;
  details: string;
  ar_details: string;
  title: string;
  ar_title: string;
  subtitle: string;
  keyword: string;
  id: any;
}
export interface AllPage {
  className?: string;
}
// const marked = window.marked;
declare global {
  interface Window {
    marked: any;
  }
}
const HomeFaq: FC<AllPage> = ({ className = "" }) => {
  let marked = window.marked; // ok now
  const [faqTitle, setFaqTitle] = useState<faqArea>();
  const [faqItems, setFaqItems] = useState<faqArea[]>();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const sectionInternational = () => {
    setLoading(true);
    axios
      .get(config.apiserver + "widgets?page_name=Questions_Section")
      .then(function (response) {
          console.log("responsefaq", response.data.data);
        const titleFaq = response.data.data.filter(
          (item: any) => item?.keyword === "faq_title"
        );
        setFaqTitle(titleFaq);
        console.log("titleFaq", titleFaq);
        const itemFaq = response.data.data.filter(
          (item: any) => item?.keyword === "faq_item"
        );
        setFaqItems(itemFaq);
        // console.log("faqItem", titleFaq);
        console.log(
          "faqItem",
          titleFaq.replace("<pre>", "").replace("</pre>", "")
        );
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    sectionInternational();
  }, []);

  const createMarkup = (html: any) => {
    return {
      __html: marked.parse(DOMPurify.sanitize(html)),
    };
  };

  return  loading ? <Loading height={800} /> : (
    <div className="p__100">
      <div className=" px-10 py-5 max-w-full   bg-neutral-100 dark:bg-black dark:bg-opacity-20 p-2 ">
        <h2 className="mt-6 text-center text-xl !leading-tight font-semibold text-neutral-900 md:text-xl xl:text-3xl dark:text-neutral-100 mb-5">
          <span
            dangerouslySetInnerHTML={createMarkup(
              localStorage.getItem("i18nextLng") === "ar"
                ? faqTitle?.ar_title
                : faqTitle?.title
            )}
          />
        </h2>

        {faqItems?.map((item: any) => (
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-white dark:bg-neutral-900 px-4 mb-5 py-6 text-left text-base font-medium text-neutral-900 dark:text-neutral-100 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span className="text-left flex w-full justify-between">
                    <span
                      dangerouslySetInnerHTML={createMarkup(
                        localStorage.getItem("i18nextLng") === "ar"
                          ? item?.ar_title
                          : item?.title
                      )}
                    />
                  </span>
                  <HiChevronDown
                    className={`${
                      open ? "rotate-180 transform" : ""
                    } h-5 w-5 text-bg-black `}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="w-full justify-between rounded-lg bg-white dark:bg-neutral-900 mt-1 mb-5 px-4 py-6 text-left text-sm font-medium text-neutral-500 dark:text-neutral-400 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <li className=" faq__dir flex w-full justify-between p-12">
                      <span
                          dangerouslySetInnerHTML={createMarkup(localStorage.getItem("i18nextLng") === "ar"
                              ? item?.ar_details
                              :item?.details
                          )}
                      />
                  </li>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  );
};

export default HomeFaq;
