import Heading from "components/Heading/Heading";
import React, {FC, useEffect, useState} from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/HIW1.png";
import HIW2img from "images/HIW2.png";
import HIW3img from "images/HIW3.png";
import VectorImg from "images/VectorHIW.svg";
import axios from "axios";
import config from "../../utils/config";
// @ts-ignore
import DOMPurify from 'dompurify';
import {heroInterface} from "../SectionHero2/SectionHero2";
import Loading from "../Loading/Loading";

export interface SectionHowItWorkProps {
  className?: string;
  data?: {
    id: number;
    title: string;
    desc: string;
    img: string;
    imgDark?: string;
  }[];
  title?: string;
  desc?: string
}

const DEMO_DATA: SectionHowItWorkProps["data"] = [
  {
    id: 1,
    img: HIW1img,
    title: "Book & relax",
    desc: "Let each trip be an inspirational journey, each room a peaceful space",
  },
  {
    id: 2,
    img: HIW2img,
    title: "Smart checklist",
    desc: "Let each trip be an inspirational journey, each room a peaceful space",
  },
  {
    id: 3,
    img: HIW3img,
    title: "Save more",
    desc: "Let each trip be an inspirational journey, each room a peaceful space",
  },
];
export interface  itemInterface{
    image: string;
    name: string;
    body:string;
    imgDark?: string;
}
const SectionHowItWork: FC<SectionHowItWorkProps> = ({
  className = "",
  data = DEMO_DATA,
  title="Book your room in 3 easy steps",
  desc=""
}) => {
  const[itemDarkOne,setItemDarkOne]=useState<itemInterface>();
  const[itemDarkTwo,setItemDarkTwo]=useState<itemInterface>();
  const[itemDarkThree,setItemDarkThree]=useState<itemInterface>();
  const[itemLightOne,setItemLightOne]=useState<itemInterface>();
  const[itemLightTwo,setItemLightTwo]=useState<itemInterface>();
  const[itemLightThree,setItemLightThree]=useState<itemInterface>();
  const [loading, setLoading] = useState(false);
  const [imgDark, setImgDark] = useState(false);

  const sectionHowitWorks= () =>{
        setLoading(true)
    axios.get(config.apiserver + 'widgets?page_name=Home')
        .then(function (response) {
          const allData = response.data.data;
          console.log( "look section " , response.data.data);
            setItemDarkOne(allData[1]);
            setItemDarkTwo(allData[2]);
            setItemDarkThree(allData[3]);
            setItemLightOne(allData[4]);
            setItemLightTwo(allData[5]);
            setItemLightThree(allData[6]);
            setLoading(false)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
            setLoading(false)
        })
  }
  useEffect(() => {
    sectionHowitWorks();
  }, []);
    const createMarkup = (html: any) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }
  return (
      loading ? <Loading /> :
                <div
                  className={`nc-SectionHowItWork  ${className}`}
                  data-nc-id="SectionHowItWork"
                >
                  <Heading isCenter desc={desc}>
                    {title}
                  </Heading>
                  <div className="mt-20 relative grid md:grid-cols-3 gap-20">
                    <img
                      className="hidden md:block absolute inset-x-0 top-10"
                      src={VectorImg}
                      alt=""
                    />
                      {imgDark ? (
                          <>
                              <div className="relative flex flex-col items-center max-w-xs mx-auto" >
                                  <NcImage containerClassName="mb-8 max-w-[200px] mx-auto" src={config.imageURL+"public"+itemDarkOne?.image}    />
                                  <div className="text-center ">
                                      <h3 className="text-xl font-semibold">{itemDarkOne?.name}</h3>
                                      <span className="block mt-5 text-neutral-500 dark:text-neutral-400" dangerouslySetInnerHTML={createMarkup(itemDarkOne?.body)}>

                          </span>
                                  </div>

                              </div>

                              <div className="relative flex flex-col items-center max-w-xs mx-auto" >
                                  <NcImage containerClassName="mb-8 max-w-[200px] mx-auto" src={config.imageURL+"public"+itemDarkTwo?.image}    />
                                  <div className="text-center ">
                                      <h3 className="text-xl font-semibold">{itemDarkTwo?.name}</h3>
                                      <span className="block mt-5 text-neutral-500 dark:text-neutral-400" dangerouslySetInnerHTML={createMarkup(itemDarkTwo?.body)}>
                                 {/*{itemTwo?.body}*/}
                          </span>
                                  </div>
                              </div>
                              <div className="relative flex flex-col items-center max-w-xs mx-auto" >
                                  <NcImage containerClassName="mb-8 max-w-[200px] mx-auto" src={config.imageURL+"public"+itemDarkThree?.image}    />
                                  <div className="text-center ">
                                      <h3 className="text-xl font-semibold">{itemDarkThree?.name}</h3>
                                      <span className="block mt-5 text-neutral-500 dark:text-neutral-400" dangerouslySetInnerHTML={createMarkup(itemDarkThree?.body)}>
                            {/*{itemThree?.body}*/}
                          </span>
                                  </div>
                              </div>

                          </>
                      ):(
                          <>
                              <div className="relative flex flex-col items-center max-w-xs mx-auto" >
                                  <NcImage containerClassName="mb-8 max-w-[200px] mx-auto" src={config.imageURL+"public"+itemLightOne?.image}    />
                                  <div className="text-center ">
                                      <h3 className="text-xl font-semibold">{itemLightOne?.name}</h3>
                                      <span className="block mt-5 text-neutral-500 dark:text-neutral-400" dangerouslySetInnerHTML={createMarkup(itemLightOne?.body)}>
                            {/*{itemOne?.body}*/}
                          </span>
                                  </div>

                              </div>

                              <div className="relative flex flex-col items-center max-w-xs mx-auto" >
                                  <NcImage containerClassName="mb-8 max-w-[200px] mx-auto" src={config.imageURL+"public"+itemLightTwo?.image}    />
                                  <div className="text-center ">
                                      <h3 className="text-xl font-semibold">{itemLightTwo?.name}</h3>
                                      <span className="block mt-5 text-neutral-500 dark:text-neutral-400" dangerouslySetInnerHTML={createMarkup(itemLightTwo?.body)}>
                                 {/*{itemTwo?.body}*/}
                          </span>
                                  </div>
                              </div>
                              <div className="relative flex flex-col items-center max-w-xs mx-auto" >
                                  <NcImage containerClassName="mb-8 max-w-[200px] mx-auto" src={config.imageURL+"public"+itemLightThree?.image}    />
                                  <div className="text-center ">
                                      <h3 className="text-xl font-semibold">{itemLightThree?.name}</h3>
                                      <span className="block mt-5 text-neutral-500 dark:text-neutral-400" dangerouslySetInnerHTML={createMarkup(itemLightThree?.body)}>
                            {/*{itemThree?.body}*/}
                          </span>
                                  </div>
                              </div>

                          </>
                      )
                      }

                    {/*{data.map((item) => (*/}
                    {/*  <div*/}
                    {/*    key={item.id}*/}
                    {/*    className="relative flex flex-col items-center max-w-xs mx-auto"*/}
                    {/*  >*/}
                    {/*    {item.imgDark ? (*/}
                    {/*      <>*/}
                    {/*        <NcImage*/}
                    {/*          containerClassName="dark:hidden block mb-8 max-w-[200px] mx-auto"*/}
                    {/*          src={item.img}*/}
                    {/*        />*/}
                    {/*        <NcImage*/}
                    {/*          containerClassName="hidden dark:block mb-8 max-w-[200px] mx-auto"*/}
                    {/*          src={item.imgDark}*/}
                    {/*        />*/}
                    {/*      </>*/}
                    {/*    ) : (*/}
                    {/*      <NcImage*/}
                    {/*        containerClassName="mb-8 max-w-[200px] mx-auto"*/}
                    {/*        src={item.img}*/}
                    {/*      />*/}
                    {/*    )}*/}

                    {/*    <div className="text-center ">*/}
                    {/*      <h3 className="text-xl font-semibold">{item.title}</h3>*/}
                    {/*      <span className="block mt-5 text-neutral-500 dark:text-neutral-400">*/}
                    {/*        {item.desc}*/}
                    {/*      </span>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*))}*/}
                  </div>
                </div>
  );
};

export default SectionHowItWork;
