import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import config from "utils/config";
import { CISUI } from '../../utils/CISUI'
import moment from "moment";


export interface BookingsProps {
	// book_unqid?: string;
	// image?: string;
	// property_name?: string;
	// discount_amount?: number;
	// grand_total?: string;
	// booking_dates?: any[];
	// room_id?: number;
	// booking_id?: number;
	check_in?: String
	check_out?: String
	booking_id?: number
	customer_name?: String
	discount_amount?: number
	grand_total?: number
	guest?: number
	image?: String
	property_name?: String
	room_id?: number
	room_no?: String
	status?: number
	total?:number
	total_discount?:number
	request_id?:number
}
const BookingRequestCard = (): JSX.Element => {

	const [bookings, setBookings] = useState<BookingsProps[]>([])


	// let obj: any = localStorage.getItem('user')
	// let user = JSON.parse(obj)
	// let id = user.customer_id;
	// console.log(id);


	const getStatus = (() => {
		axios.get(config.apiserver + `booking/pendingcustomerbookingreq`, CISUI().HeaderRequest)
			.then(function (response) {
				if(response.data.status===1){
					console.log(response.data.data);
					setBookings(response.data.data)
				}
			})
			.catch(function (error) {
				console.log(error);
			})
	})
	// moment(item.booking_dates?.[0].date).format("YYYY-MM-DD")
	// const getRooms = (() => {
		// moment(startDate).format("YYYY-MM-DD")
		// console.log(moment().format("YYYY-MM-D"));
		// console.log(moment('2020-01-01').isAfter('2019-01-01'));
		// console.log(moment(moment().format("YYYY-MM-D")).isAfter('2022-01-3'));
		// {true = 'Upcoming Booking' : ""}
	// 	axios.get(config.apiserver + `booking/customerbooking`, CISUI().HeaderRequest)
	// 		.then(function (response) {
	// 			setBookings(response.data.data)
	// 			// console.log(response.data.data);
	// 		})
	// 		.catch(function (error) {
	// 			console.log(error);
	// 		})
	// })
	// useEffect(() => {
	// 	getRooms()
	// }, [])
	useEffect(() => {
		getStatus()
	}, [])
	return (
		<>
			{
				bookings.map((item, index) =>
						<div
							className={`nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow h-full`}
							data-nc-id="PropertyCardH"
							key={item.request_id}
						>
							{/*{console.log(moment().isAfter(item.booking_dates?.[0].date))}*/}
							{/* {console.log(item.booking_dates?.[0].date)} */}
							{/* {console.log(moment().add(1,'days').isAfter(item.booking_dates?.[0].date))} */}
							<Link
								// to={`/customer/bookingdetails/${item.booking_id}`}
								// to = {`/customer/bookingrequest/${item.request_id}?sd=${item.check_in}&ed=${item.check_out}&guest=${item.guest}`}
								to = {`/customer/bookingrequest/${item.request_id}`}
								className="w-full flex flex-col sm:flex-row sm:items-center"
							>

								<div className="flex-shrink-0 p-3 w-full h-full sm:w-64 ">
									<img src={config?.imageURL + item?.image} alt="" className="h-full rounded-lg" />

									{item.discount_amount === 0 ? '' : (<div
										className={`nc-SaleOffBadge flex items-center justify-center text-xs py-0.5 px-3 text-red-50 rounded-full absolute left-5 top-5 bg-orange-500`}
										data-nc-id="SaleOffBadge"
									>
										- {item.discount_amount} KD
									</div>)}
								</div>

								<div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
									<div className="space-y-4 w-full p-2">
										<div className="flex items-center space-x-2">
											<h2 className="text-lg font-medium capitalize">
												<span className="line-clamp-2">{item.property_name}</span>
											</h2>
										</div>
										<div className="flex items-center space-x-2">
											<h2 className="text-lg font-medium capitalize">
												<span className="line-clamp-2">Room No: {item.room_no}</span>
											</h2>
										</div>
										<div className="flex items-center space-x-2">
												<span className="sm:inline-block text-sm">
                                                    Request ID:
                                                </span>
											<span className="text-md text-neutral-500 dark:text-neutral-400">
														{item.request_id}
												</span>
										</div>

										<div className="grid grid-cols-1 gap-4">

											<div className="xl:inline-grid grid-cols-2">
												<div className="flex items-center space-x-2">
                                                <span className="sm:inline-block text-sm">
                                                    <i className="las la-calendar-check text-lg" />
                                                    Check-in:
                                                </span>
													<span className="text-xs text-neutral-500 dark:text-neutral-400">
                                                    {/* {booking_dates.map((item,))} */}
														{/* {booking_dates[booking_dates.length -1]} */}
														{/*{console.log(item.booking_dates?.[0].date)}*/}
														{moment(`${item.check_in}`).format("DD MMM, YYYY")}
                                                </span>
												</div>

												{/* ---- */}

												<div className="flex items-center space-x-2">
                                                <span className="sm:inline-block text-sm">
                                                    <i className="las la-calendar-minus text-lg" />
                                                    Check-Out:
                                                </span>
													<span className="text-xs text-neutral-500 dark:text-neutral-400">

                                                     {moment(`${item.check_out}`).format("DD MMM, YYYY")}
                                                </span>
												</div>
											</div>
										</div>
										<div className="w-14 border-b border-neutral-100 dark:border-neutral-800 "></div>
										<div className="grid grid-cols-1 gap-4">
											<div className="xl:inline-grid grid-cols-2 gap-4">
											<div className="flex w-full justify-between items-end font-medium text-secondary-500 mb-4">
												Total : KD{item.total}
											</div>
											<div className="flex items-center justify-center px-3 py-2 border border-secondary-500 rounded leading-none text-base font-medium text-secondary-500">
												{item.status===0? "Request Pending": item.status===1? "Request Accepted." : item.status===2? "Request Declined" : item.status===3? "Canceled" : ""}
											</div>
											</div>
											</div>
									</div>
								</div>
							</Link>
						</div>
				)}
		</>

	)
}

export default BookingRequestCard;
