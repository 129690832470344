import axios from "axios";
import { FC, useEffect, useState } from "react";
import { SocialType } from "shared/SocialsShare/SocialsShare";
import Loading from "../../components/Loading/Loading";
import config from "../../utils/config";

// @ts-ignore
import DOMPurify from "dompurify";

export interface SocialsListProps {
  className?: string;
  itemClass?: string;
  socials?: SocialType[];
}
export interface contactInterface {
  image: string;
  name: string;
  details: string;
  title: string;
  subtitle: string;
  ar_subtitle: string;
  keyword: string;
  id: any;
}
const socialsDemo: SocialType[] = [
  {
    name: "Facebook",
    icon: "AiFillFacebook",
    href: "https://www.facebook.com/",
  },
  { name: "Twitter", icon: "lab la-twitter", href: "#" },
  { name: "Youtube", icon: "lab la-youtube", href: "https://youtube.com/" },
  {
    name: "Instagram",
    icon: "lab la-instagram",
    href: "https://instagram.com/",
  },
];
export interface itemContactUs {
  image: string;
  name: string;
  body: string;
  keyword: string;
}

const SocialsList: FC<SocialsListProps> = ({
  className = "",
  itemClass = "block",
  socials = socialsDemo,
}) => {
  const [loading, setLoading] = useState(false);
  const [facebook, setFacebook] = useState<itemContactUs>();
  const [twitter, setTwitter] = useState<itemContactUs>();
  const [youtube, setYoutube] = useState<itemContactUs>();
  const [instagram, setInstagram] = useState<itemContactUs>();

  const [social, setSocial] = useState<contactInterface[]>();

  const sectionContactUs = () => {
    setLoading(true);
    axios
      .get(config.apiserver + "widgets?page_name=Contact_Section")
      .then(function (response) {
        const allData = response.data.data;
        console.log("look contact us ", response.data.data);
        const contactLink = allData.filter(
          (item: any) => item?.keyword === "social_link"
        );
        setSocial(contactLink);
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    sectionContactUs();
  }, []);
  const createMarkup = (html: any) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  // return loading ? (
  //   <Loading />
  // ) : (
  //   <nav
  //     className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
  //     data-nc-id="SocialsList"
  //   >
  //     {social?.map((item: any) => (
  //       <a href={item?.url} target="_blank" rel="noopener noreferrer">
  //         <i
  //           className={`lab  ${item.subtitle} text-primary-6000 dark:text-neutral-300`}
  //         ></i>
  //       </a>
  //     ))}
  //   </nav>
  // );

  return (
      <nav
          className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
          data-nc-id="SocialsList"
      >
        {social?.map((item: any) => (
            <a href={item?.url} target="_blank" rel="noopener noreferrer">
              <i
                  className={`lab  ${item.subtitle} text-primary-6000 dark:text-neutral-300`}
              ></i>
            </a>
        ))}
      </nav>
  );
};

export default SocialsList;
