import {FC, Fragment, useEffect, useState} from "react";
import config from "utils/config";
// @ts-ignore
import DOMPurify from "dompurify";
import NcImage from "shared/NcImage/NcImage";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import Loading from "../../components/Loading/Loading";
export interface roomIntro {
    image: string;
    name: string;
    details: string;
    ar_details: string;
    title: string;
    ar_title: string;
    subtitle: string;
    ar_subtitle: string;
    keyword: string;
    id: any;
}
export interface AllPage {
    className?: string;
}

declare global {
    interface Window {
        marked: any;
    }
}

const settings = {
    arrows : false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};
const RoomSlider: FC<AllPage> = ({ className = "" }) =>{
    let marked = window.marked;
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const[roomItems,setRoomItems]=useState<roomIntro[]>();

    const sectionRoomSlider = () => {
        setLoading(true);
        axios
            .get(config.apiserver + "widgets?page_name=Hote_Slider")
            .then(function (response) {
                // @ts-ignore
                const roomSliderItems = response.data.data.filter((item:any) => item?.keyword==="Home_Roomintro_Slider");
                setRoomItems(roomSliderItems);
                // console.log("room===",roomSliderItems)

                setLoading(false);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                setLoading(false);
            });
    };
    useEffect(() => {
        sectionRoomSlider();
    }, []);

    const createMarkup = (html: any) => {
        return {
            __html: marked.parse(DOMPurify.sanitize(html)),
        };
    };
    // @ts-ignore
    return loading ? <Loading /> :  (
        <div className="custom-slider mb-5">
            <Slider {...settings}>
                {
                    roomItems?.map((item)=>
                        <div key={item.id}>
                            <a href={"/book-now"}>
                                <div className="item">
                                    <NcImage
                                        src={config.imageURL + "public/" + item?.image}
                                        alt=""
                                        className="room__image"
                                        width={450}
                                    />

                                    <div className="room__info">
                                        <div className="room__type dark:text-neutral-100">
                                            {localStorage.getItem("i18nextLng") === "ar"
                                                ? item?.ar_title
                                                : item?.title}
                                        </div>
                                        <p>
                                            {localStorage.getItem("i18nextLng") === "ar"
                                                ? item?.ar_subtitle
                                                : item?.subtitle}

                                        </p>
                                    </div>
                                </div>
                            </a>
                        </div>

                    )
                }
            </Slider>
        </div>
    );
};

export default RoomSlider;
