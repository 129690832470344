import {FC, Fragment, useEffect, useState} from "react";
import "react-multi-carousel/lib/styles.css";
import Section from "./SliderSection";
import WithScrollbar from "./WithScrollbar";
import {useTranslation} from "react-i18next";
import axios from "axios";
// @ts-ignore
import DOMPurify from "dompurify";
import config from "../../../utils/config";
import RoomSlider from "../RoomSlider";
import Loading from "../../../components/Loading/Loading";

export interface roomIntro {
    image: string;
    name: string;
    details: string;
    ar_details: string;
    title: string;
    ar_title: string;
    subtitle: string;
    ar_subtitle: string;
    keyword: string;
    id: any;
}
export interface AllPage {
    className?: string;
}

declare global {
    interface Window {
        marked: any;
    }
}

const RoomIntro: FC<AllPage> = ({ className = "" }) => {
    let marked = window.marked; // ok now
    const [roomIntro, setRoomIntro] = useState<roomIntro>();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const sectionHomeHero = () => {
        setLoading(true);
        axios
            .get(config.apiserver + "widgets?page_name=Hote_Slider")
            .then(function (response) {
                // const allData = response?.data?.data.filter((item:any) => item?.keyword === "Home_slider_title_area")
                const allData = response.data.data;
                // const roomIntro = allData.find((item:any)=> item?.keyword === "Home_slider_title_area" )
                const roomIntroData = allData.filter((item:any)=> item?.keyword === "Home_slider_title_area" )
                setRoomIntro({...roomIntroData[0]});
                console.log("Slider----title",roomIntro)
                setLoading(false);
            })
            .catch(function (error) {
                // @@ -52,36 +56,49 @@ const HomeIntro: FC<AllPage> = ({ className = "" }) => {
            });
    };
    useEffect(() => {
        sectionHomeHero();
    }, []);

    const createMarkup = (html: any) => {
        return {
            __html: marked.parse(DOMPurify.sanitize(html)),
        };
    };
  return  loading ? <Loading height={700} />:  (
    <div className="room__list__area ">
      <div className="info__des pb-6">
          
        <div className="text-3xl font-semibold text-neutral-900 md:text-3xl xl:text-3xl dark:text-neutral-100 md:mt-7 mb-5 uppercase tracking-wide"
            dangerouslySetInnerHTML={createMarkup(
            localStorage.getItem("i18nextLng") === "ar"
                ? roomIntro?.ar_title
                : roomIntro?.title
        )}>

        </div>
        <div className="md:text-lg text-neutral-500 dark:text-neutral-400 text-justify tracking-wide" dangerouslySetInnerHTML={createMarkup(
            localStorage.getItem("i18nextLng") === "ar"
                ? roomIntro?.ar_subtitle
                : roomIntro?.subtitle
        )}>
        </div>
      </div>
      <Fragment>
        <Section >
            <RoomSlider/>
        </Section>
      </Fragment>
    </div>
  );
};
export default RoomIntro;
