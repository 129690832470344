import React,{useState} from 'react'
import ModalVideo from 'react-modal-video'
import './ModalVideo/ModalVideo.css'
import {FaPlay} from "react-icons/fa";
const ReactModalVideoPlayer = (props:any) => {
    const [isOpen, setOpen] = useState(false)
    // @ts-ignore
    // @ts-ignore
    // const customUrl = "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
    // @ts-ignore
    // @ts-ignore
    return (
        <React.Fragment>
            {/*{console.log("propsss", props.url)}*/}
            <ModalVideo channel='custom'  isOpen={isOpen} url={props.url} onClose={() => setOpen(false)} />
            {/*<ModalVideo channel='youtube'  isOpen={isOpen} videoId={props.youtubeId} youtube={{mute:1,autoplay:1}}   onClose={() => setOpen(false)} />*/}

            {/*<button className="btn-primary" onClick={()=> setOpen(true)}>VIEW DEMO</button>*/}
            <div className="btn_wrap">
                <button className="citizensVoice" onClick={() => setOpen(true)}>
                    {" "}
                    <FaPlay className="v__icon" />
                </button>
            </div>
        </React.Fragment>
    );
};

export default ReactModalVideoPlayer;
