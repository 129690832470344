import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";




export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home",
  },
  {
    id: ncNanoId(),
    href: "/book-now",
    name: "Rooms & Rates",
  },

  {
    id: ncNanoId(),
    href: "/about",
    name: "About Hotel",
  },
  {
    id: ncNanoId(),
    href: "/gallery",
    name: "Gallery",
  },
    {
    id: ncNanoId(),
    href: "/contact",
    name: "Contact",
  },
];
