import React, {FC, useEffect, useState} from "react";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
// import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
// import SectionGridHasMap from "./SectionGridHasMap";
import { Helmet } from "react-helmet";
import imagePng from "images/holiday.jpg";
import SectionOurFeatures from "../../components/SectionOurFeatures/SectionOurFeatures";
import rightImgPng from "../../images/Image templete-01.png";
import HIW1img from "../../images/icons/HIW-1.png";
import HIW1imgDark from "../../images/icons/HIW-1-dark.png";
import HIW2img from "../../images/icons/HIW-2.png";
import HIW2imgDark from "../../images/icons/HIW-2-dark.png";
import HIW3img from "../../images/icons/HIW-3.png";
import HIW3imgDark from "../../images/icons/HIW-3-dark.png";
import SectionHowItWork from "../../components/SectionHowItWork/SectionHowItWork";
import {heroInterface} from "../../components/SectionHero2/SectionHero2";
import axios from "axios";
import config from "../../utils/config";
// @ts-ignore
import DOMPurify from 'dompurify';
import Loading from "../../components/Loading/Loading";
export interface ListingStayMapPageProps {
  className?: string;
}

const HolidayHouse: FC<ListingStayMapPageProps> = ({
  className = "",
}) => {
  const [loading, setLoading] = useState(false)
  const[hero,setHero]=useState<heroInterface>();
  const[hero2,setHero2]=useState<heroInterface>();
  useEffect(() => {
    heroAllData();
  }, []);

  const heroAllData = () =>{
    setLoading(true)
    axios.get(config.apiserver + 'widgets?page_name=Accommodation')
        .then(function (response) {
          const allData = response.data.data;
          console.log( "look " , response.data.data);
          setHero(allData[1]);
          setHero2(allData[2])
          setLoading(false)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          setLoading(false)
        })
  }
  const createMarkup = (html: any) => {
    return {
      __html: DOMPurify.sanitize(html)
    }
  }
  return (
      loading ? <Loading /> :
                <div
                  className={`nc-ListingStayMapPage relative ${className}`}
                  data-nc-id="HolidayHouse"
                >
                  <Helmet>
                    <title>Easy 2 Manage (E2M) || Service Accommodation Specialist</title>
                  </Helmet>
                  <BgGlassmorphism />

                  {/* SECTION HERO */}
                  <div className="container pt-10 pb-24 lg:pt-24 lg:pb-32">
                    <div>
                      <img src={config.imageURL+"public"+hero?.image} alt="" className="rounded-3xl w-full sm:w-full md:w-full lg:w-3/6 xl:w-3/6 2xl:w-3/6 float-right ml-5 mb-5" />
                      <div>
                        <h2 className="font-medium text-2xl md:text-3xl xl:text-4xl leading-[110%] mb-5">
                          {hero?.name}
                        </h2>
                        <span className="text-justify md:text-lg text-neutral-500 dark:text-neutral-400" dangerouslySetInnerHTML={createMarkup(hero?.body)}>

                          </span>
                        <span className="text-justify md:text-lg text-neutral-500 dark:text-neutral-400 inline-block" dangerouslySetInnerHTML={createMarkup(hero2?.body)}>

                          </span>
                      </div>
                    </div>
                  </div>
                  <SectionHowItWork className={'lg:pb-32'}
                                    title="Why Choose Us"
                                    desc=''
                                    data={[
                                      {
                                        id: 1,
                                        img: HIW1img,
                                        imgDark: HIW1imgDark,
                                        title: "Our Focus",
                                        desc: "Our main focus is to provide ease, comfort and privacy to our customers. Easy 2 manage offers fantastic hospitality and has all the amenities like home. Our apartments are fully furnished and comfortable",
                                      },
                                      {
                                        id: 2,
                                        img: HIW2img,
                                        imgDark: HIW2imgDark,
                                        title: "Our Team",
                                        desc: "Our trained team of property managers takes complete responsibility of your property. Easy 2 manage optimize the income of clients. Our team helps you and provides solution that help you grow your business in more efficient ways. ",
                                      },
                                      {
                                        id: 3,
                                        img: HIW3img,
                                        imgDark: HIW3imgDark,
                                        title: "Our Why",
                                        desc: "Easy 2 manage is passionate about bringing excellence. We have 100’s of luxurious serviced apartments, apart hotels, holiday houses and short term to choose from.",
                                      },
                                    ]}
                  />
                  {/* SECTION */}
                  {/*<div className="container pb-24 lg:pb-32 2xl:pl-10 xl:pr-0 xl:max-w-none">*/}
                  {/*  <SectionGridHasMap />*/}
                  {/*</div>*/}

                  <div className="container overflow-hidden">
                    {/* SECTION 1 */}
                    <div className="relative py-16 mb-8">
                      <BackgroundSection />
                      <SectionSliderNewCategories
                        // heading="Explore by types of stays"
                        // subHeading="Explore houses based on 10 types of stays"
                        // categoryCardType="card5"
                        // itemPerRow={5}
                        // sliderStyle="style2"
                        // id='2'
                      />
                    </div>
                    <SectionOurFeatures type="type2" rightImg={rightImgPng} />
                  </div>
                </div>
  );
};

export default HolidayHouse;
