import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
// import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import React, {FC, useEffect, useState} from "react";
// import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
import SectionHowItWork from "../../components/SectionHowItWork/SectionHowItWork";

import HIW1img from "images/icons/HIW-1.png";
import HIW2img from "images/icons/HIW-2.png";
import HIW3img from "images/icons/HIW-3.png";
import HIW1imgDark from "images/icons/HIW-1-dark.png";
import HIW2imgDark from "images/icons/HIW-2-dark.png";
import HIW3imgDark from "images/icons/HIW-3-dark.png";
// import Heading from "../../components/Heading/Heading";
import SectionOurFeatures from "../../components/SectionOurFeatures/SectionOurFeatures";
import rightImgPng from "../../images/Image templete-01.png";
import rightImgPng2 from "../../images/short.jpg";
import {heroInterface} from "../../components/SectionHero2/SectionHero2";
import axios from "axios";
import config from "../../utils/config";
// @ts-ignore
import DOMPurify from 'dompurify';
import Loading from "../../components/Loading/Loading";
export interface ListingStayPageProps {
    className?: string;
}

const ShortTermRent: FC<ListingStayPageProps> = ({ className = "" }) => {
    const [loading, setLoading] = useState(false)
    const[hero,setHero]=useState<heroInterface>();
    useEffect(() => {
        heroAllData();
    }, []);

    const heroAllData = () =>{
        setLoading(true)
        axios.get(config.apiserver + 'widgets?page_name=Accommodation')
            .then(function (response) {
                const allData = response.data.data;
                console.log( "look " , response.data.data);
                setHero(allData[3]);
                setLoading(false)
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                setLoading(false)
            })
    }
    const createMarkup = (html: any) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }
    return (
        loading ? <Loading /> :
                <div
                    className={`nc-ListingStayPage relative overflow-hidden ${className}`}
                    data-nc-id="Accommodation"
                >
                    <Helmet>
                        <title>Easy 2 Manage (E2M) || Service Accommodation Specialist</title>
                    </Helmet>
                    <BgGlassmorphism />

                    <div className="container relative overflow-hidden">
                        {/* SECTION HERO */}
                        <div
                            className={`nc-SectionHeroArchivePage flex flex-col relative`}
                            data-nc-id="SectionHeroArchivePage"
                        >
                            <div className="flex flex-col lg:flex-row lg:items-center">
                                <div
                                    className={` pt-10 pb-2 lg:pb-10 lg:pt-16 flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-6 lg:space-y-10 pb-14 lg:pb-32 xl:pb-64 xl:pr-14 lg:mr-10 xl:mr-0`}>
                                    <h2 className="font-medium text-2xl md:text-3xl xl:text-4xl leading-[110%]">
                                        {/*{coverTitle}*/}
                                        {hero?.name}
                                    </h2>
                                    <div className="flex items-center text-justify md:text-lg text-neutral-500 dark:text-neutral-400">
                                <span className="ml-2.5" dangerouslySetInnerHTML={createMarkup(hero?.body)}>
                                    {/*{subTitle}*/}
                                    {/*{subTitle}*/}
                                </span>
                                    </div>
                                    <div className="flex items-center text-justify md:text-lg text-neutral-500 dark:text-neutral-400">
                                        <span className="ml-2.5">
                                            {/*{subTitle2}*/}
                                        </span>
                                    </div>

                                </div>

                                <div className="flex-grow lg:-mt-40 xl:-mt-56">
                                    <img className="w-full rounded-3xl" src={config.imageURL+"public"+hero?.image} alt="hero"/>
                                </div>
                            </div>

                        </div>

                        {/* SECTION 2 */}
                        <SectionHowItWork className={'lg:pb-32'}
                                          title = "Why Choose Us"
                                          desc=''
                                          data={[
                                              {
                                                  id: 1,
                                                  img: HIW1img,
                                                  imgDark: HIW1imgDark,
                                                  title: "Our Focus",
                                                  desc: "Our main focus is on providing excellent services to our guests and clients. Easy to manage is offering excellent services all across in the United Kingdom. All the guests and clients are always satisfied by our services.",
                                              },
                                              {
                                                  id: 2,
                                                  img: HIW2img,
                                                  imgDark: HIW2imgDark,
                                                  title: "Our Team",
                                                  desc: "Our team of well trained and skilled staff knows how to maximize the earning of your property and how to unlock its earning potential. Easy 2 manage list, maintain and also optimize the property of its clients.",
                                              },
                                              {
                                                  id: 3,
                                                  img: HIW3img,
                                                  imgDark: HIW3imgDark,
                                                  title: "Our Why",
                                                  desc: "Easy 2 manage gives you a totally hands off business. Not only this we also hand over the keys and profits as well. We always focused on maximizing the income of our clients and also creating hands off business for them.",
                                              },
                                          ]}
                        />

                        {/* SECTION 1 */}
                        <div className="relative py-16">
                            <BackgroundSection />
                            <SectionSliderNewCategories
                                // heading="Explore by types of stays"
                                // subHeading="Explore houses based on 10 types of stays"
                                // categoryCardType="card5"
                                // itemPerRow={5}
                                // sliderStyle="style2"
                                // id='3'
                            />
                        </div>

                        {/* SECTION */}

                        {/* SECTION 6 */}
                        <SectionOurFeatures type="type2" rightImg={rightImgPng} />

                    </div>
                </div>
    );
};

export default ShortTermRent;
