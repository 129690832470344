import React from "react";
import { BrowserRouter, Switch, Route, Redirect, RouteProps } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import Header from "shared/Header/Header";
import Page404 from "containers/Page404/Page404";
import Accommodation from "containers/Services/Accommodation";
import HolidayHouse from "containers/Services/HolidayHouse";
import ShortTermRent from "containers/Services/ShortTermRent";
import Apartment from "containers/Services/Apartment";
// import Gallery from "containers/Company/Gallery";

import PropertyDetailPage from "containers/ListingDetailPage/PropertyDetailPage";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/Pay/PayPage";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/Company/PageAbout";
import PageSignUp from "containers/SignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import OtpVerify from "containers/SignUp/OtpVerify";
// import BlogPage from "containers/BlogPage/BlogPage";
// import BlogSingle from "containers/BlogPage/BlogSingle";

import PageHome from "containers/PageHome/PageHome";

import Property from "../containers/OwnerPortal/Property";
import BookingReport from "../containers/OwnerPortal/BookingReport";
import PaymentHistory from "../containers/OwnerPortal/PaymentHistory";
import ProfileSetup from "../containers/OwnerPortal/ProfileSetup";
import Service from "../containers/OwnerPortal/Services";
import Customer from "../containers/CustomerPortal/Customer";
// import RooomDetails from "../components/PropertyCardH/RoomDetails"
import SearchList from "../containers/PageHome/SearchList";
import BookingPage from "../components/PropertyCardH/BookingPage";
import { CISUI } from "../utils/CISUI";
import CustomerProfileSetup from "../containers/CustomerPortal/CustomerProfileSetup";
import CustomerBookingHistory from "../containers/CustomerPortal/CustomerBookingHistory";
import CustomerUpcomingBooking from "../containers/CustomerPortal/CustomerUpcomingBooking";
import CustomerLogout from "../containers/CustomerPortal/CustomerLogout";
import Terms from "../containers/PageHome/terms";
import BookingDetails from "components/BookingDetails/BookingDetails";
import CustomerPaymentHistory from "../containers/CustomerPortal/CustomerPaymentHistory";
import ForgotPass from "containers/ForgotPass/ForgotPass";
import PassReset from "containers/ForgotPass/PassReset";
import NidVerification from "containers/CustomerPortal/NidVerification";
import Complaint from "containers/Complaint/Complaint";
import BookingRequestDetails from "containers/CustomerPortal/BookingRequestDetails";
import CustomerBookingRequest from "../containers/CustomerPortal/CustomerBookingRequest";
import RequestCheckOut from "containers/CheckOutPage/RequestCheckOut";
import CitiesBlog from "../containers/PageHome/CitiesBlog";
import Gallery from "../containers/Company/Gallery";
export const pages: Page[] = [
    { path: "/", exact: true, component: PageHome },

    { path: "/accommodation", component: Accommodation },
    { path: "/holiday-house", component: HolidayHouse },
    { path: "/short-term-rent", component: ShortTermRent },
    { path: "/apartment", component: Apartment },

    { path: "/property-details/:id", component: PropertyDetailPage },
    { path: "/cities-blog/:id", component: CitiesBlog },
    { path: "/contact", component: PageContact },
    { path: "/about", component: PageAbout },
    { path: "/signup/:id", component: PageSignUp},
    { path: "/login/:id", component: PageLogin },
    { path: "/forgot-pass", component: ForgotPass },
    { path: "/password-reset", component: PassReset },
    { path: "/terms", component: Terms },
    // {path: "/roomDetails", component: RooomDetails},
    { path: "/search", component: SearchList },
    { path: "/book-now", component: BookingPage },
    { path: "/complaint", component: Complaint },
    { path: "/otp-verification", component: OtpVerify},
];
export const pages2: Page[] = [
    { path: "/owner/my-property", exact: true, component: Property },
    { path: "/owner/booking-report", exact: true, component: BookingReport },
    { path: "/owner/payment-history", exact: true, component: PaymentHistory },
    { path: "/owner/services", exact: true, component: Service },
    { path: "/owner/account-setup", exact: true, component: ProfileSetup },
    { path: "/owner/message", exact: true, component: ProfileSetup },
    { path: "/customer/customer", exact: true, component: Customer },
    { path: "/customer/customer-upcoming-booking", exact: true, component: CustomerUpcomingBooking },
    { path: "/customer/customer-booking-request", exact: true, component: CustomerBookingRequest },
    { path: "/customer/customer-booking-history", exact: true, component: CustomerBookingHistory },
    { path: "/customer/customer-profile-setup", exact: true, component: CustomerProfileSetup },
    { path: "/customer/customer-payment-history", exact: true, component: CustomerPaymentHistory },
    { path: "/customer/customer-logout", component: CustomerLogout },
    { path: "/checkout/:id", component: CheckOutPage },
    { path: "/pay-done/:id", component: PayPage },
    { path: "/customer/bookingdetails/:id", component: BookingDetails },

    // { path: "/customer/bookingrequest/:id", component: BookingRequest },

    { path: "/customer/bookingrequest/:id", component: BookingRequestDetails},

    { path: "/customer/nid-verification", component: NidVerification },
    { path: "/requestcheckout/:id", component: RequestCheckOut },
]
interface AuthRouteProps extends RouteProps {
    component: any;
    authenticated: boolean;
    rest?: any
}
const PrivateRoute: React.FC<AuthRouteProps> = ({ component: Component, authenticated, path, ...rest }: any) => (
    <Route
        {...rest}
        path={path}
        render={(props) => {
            // console.log(authenticated)
            return localStorage.getItem('access_token') ?
                <Component {...props} /> : (
                    CISUI().notification('warning', 'Please login first!'),
                    <Redirect to="/login/customer" />)
        }}
    />);


const Routes = () => {
    let authUser: boolean = false;
    if (localStorage.getItem('access_token')) {
        authUser = true;
    }
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Header />
            <Switch>
                {pages.map(({ component, path, exact }) => {
                    return (
                        <Route
                            key={path}
                            component={component}
                            exact={!!exact}
                            path={path}
                        />
                    );
                })}

                {pages2.map(({ component, path }, index) => {
                    return (
                        <PrivateRoute key={index} component={component} authenticated={authUser} path={path} />
                    )
                })}
                <Route component={Gallery} />
            </Switch>
                <div className="mt-20">
                <Footer />
                </div>

        </BrowserRouter>
    );
};

export default Routes;
