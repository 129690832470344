import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


// @ts-ignore
import DOMPurify from "dompurify";
import { Helmet } from "react-helmet";

import axios from "axios";
import GoogleMapReact from "google-map-react";
import NcImage from "shared/NcImage/NcImage";
import LocationMarker from "../../components/AnyReactComponent/LocationMarker";
import config from "../../utils/config";
import Loading from "../../components/Loading/Loading";
//@ts-ignore

export interface aboutArea {
  image: string;
  name: string;
  details: string;
  ar_details: string;
  title: string;
  ar_title: string;
  subtitle: string;
  ar_subtitle: string;
  keyword: string;
  id: any;
}
export interface AllPage {
  className?: string;
}
export interface aboutInterface {
  image: string;
  name: string;
  body: string;
  imgDark?: string;
}
// const marked = window.marked;
declare global {
  interface Window {
    marked: any;
  }
}
const PageAbout: FC<AllPage> = ({ className = "" }) => {
  let marked = window.marked; // ok now
  let lt = "29.3677836";
  let lg = "47.9773467";
  const { t } = useTranslation();

  const [aboutUs, setAboutUs] = useState<aboutArea>();
  const [investors, setInvestors] = useState<aboutInterface>();
  const [loading, setLoading] = useState(false);
  const sectionHowitWorks = () => {
    axios
      .get(config.apiserver + "widgets?page_name=About_Section")
      .then(function (response) {
        const allData = response.data.data;
        const aboutData = allData.filter(
          (item: any) => item?.keyword === "about"
        );
        setAboutUs({ ...aboutData[0] });

        console.log("about_Section", aboutData);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  useEffect(() => {
    sectionHowitWorks();
  }, []);
  const createMarkup = (html: any) => {
    return {
      __html: marked.parse(DOMPurify.sanitize(html)),
    };
  };
  return   loading ? <Loading height={400} /> :(
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About Us</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28 about__area">

        <div>
          <NcImage
            src={config.imageURL + "public/" + aboutUs?.image}
            alt=""
            className="about___image w-full sm:w-full !h-1/2 md:w-full lg:w-3/6 xl:w-3/6 2xl:w-3/6 float-right ml-5 mb-5"
          />
          <div>

            <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100 mb-5 ">
              {localStorage.getItem("i18nextLng") === "ar"
                ? aboutUs?.ar_title
                : aboutUs?.title}
            </h2>
            <span className="text-left  dark:text-neutral-400">
              <p className="text-justify detail__intro">


                             <span
                                 dangerouslySetInnerHTML={createMarkup(localStorage.getItem("i18nextLng") === "ar"
                                     ? aboutUs?.ar_details.replace(/(<([^>]+)>)/gi, "")
                                     :aboutUs?.details.replace(/(<([^>]+)>)/gi, "")
                                 )}
                             />
              </p>
            </span>
          </div>
        </div>

        <div>
          <div className="listingSection__wrap  my-8 p-6 bg-neutral-100 dark:bg-black dark:bg-opacity-20">
            <div className="text-center">
              <h2 className="text-2xl font-semibold">{t("map_title")} </h2>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                <i className="las la-map-marker-alt mr-1"></i>
                {t("map_address")}
              </span>
            </div>

            <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
              <div className=" overflow-hidden">
                {lt !== "" ? (
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: "AIzaSyDxJaU8bLdx7sSJ8fcRdhYS1pLk8Jdvnx0",
                    }}
                    defaultZoom={15}
                    yesIWantToUseGoogleMapApiInternals
                    defaultCenter={{
                      lat: parseFloat(lt),
                      lng: parseFloat(lg),
                    }}
                  >
                    <LocationMarker lat={parseFloat(lt)} lng={parseFloat(lg)} />
                  </GoogleMapReact>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageAbout;
