import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import config from "utils/config";
import { CISUI } from '../../utils/CISUI'
import moment from "moment";


export interface BookingsProps {
    book_unqid?: string;
    image?: string;
    property_name?: string;
    discount_amount?: number;
    grand_total?: string;
    booking_dates?: any[];
    room_id?: number;
    booking_id?: number;
}
const BookedRoom = (): JSX.Element => {

    const [bookings, setBookings] = useState<BookingsProps[]>([])


    // let obj: any = localStorage.getItem('user')
    // let user = JSON.parse(obj)
    // let id = user.customer_id;
    // console.log(id);


    const getRooms = (() => {
        // moment(startDate).format("YYYY-MM-DD")
        // console.log(moment().format("YYYY-MM-D"));
        console.log(moment('2020-01-01').isAfter('2019-01-01'));
        // console.log(moment(moment().format("YYYY-MM-D")).isAfter('2022-01-3'));
        // {true = 'Upcoming Booking' : ""}
        axios.get(config.apiserver + `booking/customerbooking`, CISUI().HeaderRequest)
            .then(function (response) {
                if(response.data.status===1) {
                    setBookings(response.data.data)
                    console.log(response.data.data);
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    })
    // moment(item.booking_dates?.[0].date).format("YYYY-MM-DD")

    useEffect(() => {
        getRooms()
    }, [])
    return (
        <>
            {
                bookings.map((item, index) =>
                    moment(moment(item.booking_dates?.[0]?.date).format("YYYY-MM-DD")).isAfter(moment().format("YYYY-MM-DD")) ?
                        <div
                            className={`nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow h-full`}
                            data-nc-id="PropertyCardH"
                            key={item.book_unqid}
                        >
                            {console.log(moment().isAfter(item.booking_dates?.[0].date))}
                            {/* {console.log(item.booking_dates?.[0].date)} */}
                            {/* {console.log(moment().add(1,'days').isAfter(item.booking_dates?.[0].date))} */}
                            <Link
                                to={`/customer/bookingdetails/${item.booking_id}`}
                                className="w-full flex flex-col sm:flex-row sm:items-center"
                            >
                                <div className="flex-shrink-0 p-3 w-full h-full sm:w-64 ">
                                    <img src={config.imageURL + item.image} alt="" className="h-full rounded-lg" />

                                    {item.discount_amount === 0 ? '' : (<div
                                        className={`nc-SaleOffBadge flex items-center justify-center text-xs py-0.5 px-3 text-red-50 rounded-full absolute left-5 top-5 bg-orange-500`}
                                        data-nc-id="SaleOffBadge"
                                    >
                                        - {item.discount_amount} KD
                                    </div>)}
                                </div>

                                <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
                                    <div className="space-y-4 w-full p-2">
                                        <div className="flex items-center space-x-2">
                                            <h2 className="text-lg font-medium capitalize">
                                                <span className="line-clamp-2">{item.property_name}</span>
                                            </h2>
                                        </div>

                                        <div className="grid grid-cols-1 gap-4">

                                            <div className="inline-grid grid-cols-2">
                                                <div className="flex items-center space-x-2">
                                                    <span className="sm:inline-block text-sm">
                                                        <i className="las la-calendar-check text-lg" />
                                                        Check-in:
                                                    </span>
                                                    <span className="text-xs text-neutral-500 dark:text-neutral-400">
                                                        {/* {booking_dates.map((item,))} */}
                                                        {/* {booking_dates[booking_dates.length -1]} */}
                                                        {console.log(item.booking_dates?.[0].date)}
                                                        {moment(item.booking_dates?.[0].date).format("DD MMM, YYYY")}
                                                    </span>
                                                </div>

                                                {/* ---- */}

                                                <div className="flex items-center space-x-2">
                                                    <span className="sm:inline-block text-sm">
                                                        <i className="las la-calendar-minus text-lg" />
                                                        Check-Out:
                                                    </span>
                                                    <span className="text-xs text-neutral-500 dark:text-neutral-400">

                                                        {moment(item.booking_dates?.[item.booking_dates?.length - 1]?.date).format("DD MMM, YYYY")}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 "></div>
                                        <div className="flex w-full justify-between items-end font-medium text-secondary-500">
                                            Total :KD{item.grand_total}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        : ""
                )}
        </>

    )
}

export default BookedRoom
