import { FC, useEffect, useState } from "react";
import Slider from "react-slick";

import axios from "axios";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import config from "utils/config";
// @ts-ignore
import DOMPurify from "dompurify";
import NcImage from "shared/NcImage/NcImage";
import Loading from "../../components/Loading/Loading";
//@ts-ignore

export interface heroArea {
  image: string;
  name: string;
  body: string;
  title: string;
  ar_title: string;
  subtitle: string;
  ar_subtitle: string;
  keyword: string;
  id: any;
}
export interface AllPage {
  className?: string;
}
const HomeSlider: FC<AllPage> = ({ className = "" }) => {

  const [heroSliders, setHeroSliders] = useState<heroArea[]>();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  //@ts-ignore
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    delay: 1000,
    arrows: false,
  };

  const sectionHomeHero = () => {
    setLoading(true);
    axios
      .get(config.apiserver + "allbanners?page_name=Hero_Section")
      .then(function (response) {
        const allData = response.data.data;
        const sliderData = allData.filter(
          (item: any) => item?.page_name === "Hero_Section"
        );
        setHeroSliders(sliderData);
        console.log("HeroSection ", sliderData);
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    sectionHomeHero();
  }, []);

  const createMarkup = (html: any) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  return loading ? <Loading height={700} /> :  (
    <div>
      <Slider {...settings}>
        {heroSliders?.map((item) => (
          <div className="hero__slider" key={item.id}>
            <NcImage
              src={config.imageURL + "public/upload/cms/banner/" + item?.image}
              alt=""
              className="slider_image"
            />
            <div className="hero__info">
              <div className="hero__title  ">
                {localStorage.getItem("i18nextLng") === "ar"
                  ? item?.ar_title
                  : item?.title}
              </div>
              <div className="hero__sub ">
                {localStorage.getItem("i18nextLng") === "ar"
                  ? item?.ar_subtitle
                  : item?.subtitle}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HomeSlider;
