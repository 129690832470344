// import React from "react";
import ReactDOM from "react-dom";
//
import "rc-slider/assets/index.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
// STYLE
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import "./index.scss";
import "./styles/index.scss";

//
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import {Elements} from "@stripe/react-stripe-js";
// import {loadStripe} from "@stripe/stripe-js";
import "./i18n";

ReactDOM.render(
  <App />,

  document.getElementById("root")
);

reportWebVitals();
