import React, { FC } from "react";
import { Helmet } from "react-helmet";
import LeftNav from "../../components/Portal/LeftNav";
import avaterPic from "images/avatars/avater.png";
import ListView from "../../components/ListView/ListView";
export interface BookingReportPageProps {
    className?: string;
}


const BookingReport: FC<BookingReportPageProps> = ({ className = "" }) => {

    return (
        <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
            <Helmet>
                <title>Easy 2 Manage (E2M) || The Property Management Company</title>
            </Helmet>
            <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
                <LeftNav
                    profile={avaterPic}
                    username='Demo User'
                    designation='Hotel Owner'
                    type='owner'
                />
                <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
                    <ListView
                        pageTitle='Booking Report'
                        desc=''
                    />
                </div>
            </main>
        </div>
    );
};

export default BookingReport;
