import React, {FC, useEffect, useState} from "react";
import rightImgPng from "images/Image templete-01.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import axios from "axios";
import config from "../../utils/config";
import Loading from "../Loading/Loading";
// import DOMPurify from 'dompurify';
export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
}
export interface  itemBennefits{
  image: string;
  name: string;
  body:string;
  keyword:string;
}
const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({  className = "lg:py-14",  rightImg = rightImgPng,  type = "type1",   }) => {
  const [loading, setLoading] = useState(false);
  const[bennefitsIntro,setBennefitsIntro]=useState<itemBennefits>();
  const[bennefitsSingleItemOne,setBennefitsSingleItemOne]=useState<itemBennefits>();
  const[bennefitsSingleItemTwo,setBennefitsSingleItemTwo]=useState<itemBennefits>();
  const[bennefitsSingleItemThree,setBennefitsSingleItemThree]=useState<itemBennefits>();
  const[bennefitsSingleItemFour,setBennefitsSingleItemFour]=useState<itemBennefits>();
  const[bennefitsSingleItemFive,setBennefitsSingleItemFive]=useState<itemBennefits>();
  const[bennefitsSingleItemSix,setBennefitsSingleItemSix]=useState<itemBennefits>();


  const sectionBennefits= () =>{
    setLoading(true)
    axios.get(config.apiserver + 'widgets?page_name=Home')
        .then(function (response) {
          const allData = response.data.data;
          console.log( "look section " , response.data.data);
          setBennefitsIntro(allData[7]);
          setBennefitsSingleItemOne(allData[8]);
          setBennefitsSingleItemTwo(allData[9]);
          setBennefitsSingleItemThree(allData[10]);
          setBennefitsSingleItemFour(allData[11]);
          setBennefitsSingleItemFive(allData[12]);
          setBennefitsSingleItemSix(allData[13]);
          setLoading(false)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          setLoading(false)
        })
  }
  useEffect(() => {
    sectionBennefits();
  }, []);
  const createMarkup = (html: any) => {
    return {
      // __html: DOMPurify.sanitize(html)
    }
  }
  return (
      loading ? <Loading /> :
      <div
          className={`nc-SectionOurFeatures relative flex flex-col items-center ${
              type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
          } ${className}`}
          data-nc-id="SectionOurFeatures"
      >
        <div className="flex-grow">
          <NcImage src={config.imageURL+"public"+bennefitsIntro?.image}  />
        </div>
        <div
            className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${
                type === "type1" ? "lg:pl-16" : "lg:pr-16"
            }`}
        >
        <span className="uppercase text-sm text-gray-400 tracking-widest">
           {bennefitsIntro?.keyword}
        </span>
          <h2 className="font-semibold text-4xl mt-5"> {bennefitsIntro?.name}</h2>

          <ul className="space-y-4 mt-10">
            <li className="flex items-center space-x-4">
              <Badge name="01" />
              <span className="font-medium text-neutral-700 dark:text-neutral-300">
              {/*YEARS OF EXPERIENCE*/}
                {bennefitsSingleItemOne?.name}
            </span>
            </li>
            <li className="flex items-center space-x-4">
              <Badge  name="02" />
              <span className="font-medium text-neutral-700 dark:text-neutral-300">
              {/*ADVANCE MANAGEMENT SYSTEM*/}
                {bennefitsSingleItemTwo?.name}
            </span>
            </li>
            <li className="flex items-center space-x-4">
              <Badge  name="03" />
              <span className="font-medium text-neutral-700 dark:text-neutral-300">
              {/*BEST CUSTOMER SERVICES*/}
                {bennefitsSingleItemThree?.name}
            </span>
            </li>
            <li className="flex items-center space-x-4">
              <Badge  name="04" />
              <span className="font-medium text-neutral-700 dark:text-neutral-300">
              {/*COMPETITIVE COMISSION RATES*/}
                {bennefitsSingleItemFour?.name}
            </span>
            </li>
            <li className="flex items-center space-x-4">
              <Badge  name="05" />
              <span className="font-medium text-neutral-700 dark:text-neutral-300">
              {/*PROFESSIONAL STAFF*/}
                {bennefitsSingleItemFive?.name}
            </span>
            </li>
            <li className="flex items-center space-x-4">
              <Badge  name="06" />
              <span className="font-medium text-neutral-700 dark:text-neutral-300">
              {/*IN HOUSE CLEANING STAFF*/}
                {bennefitsSingleItemSix?.name}
            </span>
            </li>
            {/*<li className="flex items-center space-x-4">*/}
            {/*  <Badge  name="07" />*/}
            {/*  <span className="font-medium text-neutral-700 dark:text-neutral-300">*/}
            {/*    /!*7 DAYS QUICK GUEST RESPONSE*!/*/}
            {/*  </span>*/}
            {/*</li>*/}
            {/*<li className="flex items-center space-x-4">*/}
            {/*  <Badge  name="08" />*/}
            {/*  <span className="font-medium text-neutral-700 dark:text-neutral-300">*/}
            {/*    LINEN & TOWELS SUPPLY*/}
            {/*  </span>*/}
            {/*</li>*/}
            {/*<li className="flex items-center space-x-4">*/}
            {/*  <Badge  name="09" />*/}
            {/*  <span className="font-medium text-neutral-700 dark:text-neutral-300">*/}
            {/*    PROPERTY SOURCING*/}
            {/*  </span>*/}
            {/*</li>*/}
            {/*<li className="flex items-center space-x-4">*/}
            {/*  <Badge  name="10" />*/}
            {/*  <span className="font-medium text-neutral-700 dark:text-neutral-300">*/}
            {/*    REVIEWS MANAGEMENT & GUEST QUERIES*/}
            {/*  </span>*/}
            {/*</li>*/}
          </ul>

        </div>
      </div>
  );
};

export default SectionOurFeatures;