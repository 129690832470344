import React, { HTMLAttributes, ReactNode } from "react";
import Avatar from "../../shared/Avatar/Avatar";
import CardCategory1 from "../CardCategory1/CardCategory1";
import { TaxonomyType } from "../../data/types";
import { Link } from "react-router-dom";
// import {DEMO_CATEGORIES} from "../../data/taxonomies";
// import avaterPic from "../../images/avatars/avater.png";
// import {useHistory} from "react-router-dom";

export interface LeftNavProps extends HTMLAttributes<HTMLHeadingElement> {
    fontClass?: string;
    desc?: ReactNode;
    isCenter?: boolean;
    username?: string;
    profile?: string;
    designation?: string;
    ownerCtg?: TaxonomyType[];
    customerCtg?: TaxonomyType[];
    type?: string;

}

const OWNER_CATS: TaxonomyType[] = [
    {
        id: "1",
        href: "/owner/my-property",
        name: "Booked Properties",
        taxonomy: "category",
        icon: "las la-city",
    },
    {
        id: "2",
        href: "/owner/payment-history",
        name: "Payment History",
        taxonomy: "category",
        icon: "las la-money-check-alt",
    },
    {
        id: "3",
        href: "/owner/account-setup",
        name: "Account Setup",
        taxonomy: "category",
        icon: "las la-user-tie",
    },
    // {
    //     id: "1",
    //     href: "/owner/my-property",
    //     name: "My Property",
    //     taxonomy: "category",
    //     icon: "las la-city",
    // },
    // {
    //     id: "2",
    //     href: "/owner/booking-report",
    //     name: "Bookings/Earning Report\n",
    //     taxonomy: "category",
    //     icon: "las la-plus-square",
    // },
    // {
    //     id: "3",
    //     href: "/owner/payment-history",
    //     name: "Payment History",
    //     taxonomy: "category",
    //     icon: "las la-money-check-alt",
    // },
    // {
    //     id: "4",
    //     href: "/owner/services",
    //     name: "Services",
    //     taxonomy: "category",
    //     icon: "las la-assistive-listening-systems",
    // },
    // {
    //     id: "5",
    //     href: "/owner/account-setup",
    //     name: "Account Setup",
    //     taxonomy: "category",
    //     icon: "las la-user-tie",
    // },
    // {
    //     id: "6",
    //     href: "/owner/message",
    //     name: "Messages",
    //     taxonomy: "category",
    //     icon: "las la-comments",
    // },
    //
    // {
    //     id: "7",
    //     href: "/logout",
    //     name: "Logout",
    //     taxonomy: "category",
    //     icon: "las la-sign-in-alt",
    // },

];

const CUS_CATS: TaxonomyType[] = [
    {
        id: "1",
        href: "/customer/customer-upcoming-booking",
        name: "Upcoming Booking",
        taxonomy: "category",
        icon: "las la-city",
    },
    {
        id: "6",
        href: "/customer/customer-booking-request",
        name: "Booking Request",
        taxonomy: "category",
        icon: "las la-hotel",
    },
    {
        id: "2",
        href: "/customer/customer-booking-history",
        name: "Booking History",
        taxonomy: "category",
        icon: "las la-history",
    },
    {
        id: "3",
        href: "/customer/customer-payment-history",
        name: "Payment History",
        taxonomy: "category",
        icon: "las la-money-check-alt",
    },
    {
        id: "4",
        href: "/customer/customer-profile-setup",
        name: "Account Setup",
        taxonomy: "category",
        icon: "las la-user-tie",
    },
    {
        id: "5",
        href: "customer/customer-logout",
        name: "Logout",
        taxonomy: "category",
        icon: "las la-sign-in-alt",
    },
];

const LeftNav: React.FC<LeftNavProps> = ({
                                             className = "mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50",
                                             isCenter = false,
                                             username = '',
                                             profile = '',
                                             ownerCtg = OWNER_CATS,
                                             customerCtg = CUS_CATS,
                                             designation = '',
                                             type = ''
                                         }) => {


    const customer: any = localStorage.getItem('user')
    let user = JSON.parse(customer)

    console.log(user);


    return (
        <div
            className=" w-full flex flex-col items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 sm:p-6 xl:p-8 bg-neutral-100 dark:bg-black dark:bg-opacity-20 p-5 rounded-2xl" >

            <div className="w-full block flex-grow mb-24 lg:mb-0">
                <div className="lg:sticky lg:top-24">

                    <Avatar
                        imgUrl={profile}
                        // hasChecked
                        hasChecked={user?.is_verified === 1 ? true : false}
                        hasCheckedClass="w-6 h-6 -top-0.5 right-2"
                        sizeClass="w-28 h-28"
                    />

                    {/* ---- */}
                    <div className="space-y-1 text-center flex flex-col items-center">
                        <h3 className="text-2xl font-semibold lg:pt-5">{username}</h3>
                        <span className="block text-neutral-500 dark:text-neutral-400 pb-3">{designation}</span>
                    </div>
                    <div className="text-center flex flex-col items-center mb-6">
                        <div className="border-b border-neutral-200 dark:border-neutral-700 w-14 mb-4"></div>
                    </div>
                    {/* {user?.is_verified !== 0 && (
                        <Link to="/customer/nid-verification">
                            <div className="text-center py-4 lg:px-4">
                                <div className="p-2 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex bg-primary-6000" role="alert">
                                    <span className="font-semibold mr-2 text-left flex-auto px-2 py-1">Please verify your NID</span>
                                    <svg className="fill-current opacity-75 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" /></svg>
                                </div>
                            </div>
                        </Link>
                    )} */}



                    {/* ---- */}

                    <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">

                        {type === "owner" ? (ownerCtg.map((owner) => (
                                <CardCategory1
                                    className="p-2 xl:p-3"
                                    key={owner.id}
                                    taxonomy={owner}
                                    size="normal"
                                    path={owner.href}
                                />
                            ))) :
                            (customerCtg.map((customer) => (
                                <CardCategory1
                                    className="p-2 xl:p-3"
                                    key={customer.id}
                                    taxonomy={customer}
                                    size="normal"
                                    path={customer.href}
                                />
                            )))
                        }
                    </div>

                </div>
            </div>

        </div>
    );
};

export default LeftNav;