// import { Tab } from "@headlessui/react";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import LeftNav from "../../components/Portal/LeftNav";
import avaterPic from "images/avatars/avater.png";
import ButtonSecondary from "../../shared/Button/ButtonSecondary";
import PropertyCardH from "../../components/PropertyCardH/PropertyCardH";
export interface PropertyPageProps {
    className?: string;
}

const property = () => {
   return(
       <div className="listingSection__wrap">
           <div>
               <h2 className="text-2xl font-semibold">My PropertyInfo</h2>
           </div>
           <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
           <PropertyCardH/>
           <div className="flex mt-11 justify-center items-center">
               <ButtonSecondary>Show me more</ButtonSecondary>
           </div>
       </div>
)
}
const Property: FC<PropertyPageProps> = ({ className = "" }) => {

    return (
        <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
            <Helmet>
                <title>Easy 2 Manage (E2M) || The PropertyInfo Management Company</title>
            </Helmet>
            <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
                <LeftNav
                    profile={avaterPic}
                    username='Demo User'
                    designation='Hotel Owner'
                    type='owner'
                />
                <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
                    {/*<ListView*/}
                    {/*    pageTitle='My PropertyInfo'*/}
                    {/*    desc='' />*/}
                    {property()}

                </div>
            </main>
        </div>
    );
};

export default Property;
