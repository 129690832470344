import React, {useEffect, useState} from "react";
import MyRouter from "routers/index";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import config from "./utils/config";
import "./App.css"
import Loading from "components/Loading/Loading";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function App() {

    const stripePromise = loadStripe(config.stripe_key)

        const [loading, setLoading] = useState(true);

    useEffect(()=>setLoading(false),[])
        return (
            <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
                <Elements stripe={stripePromise}>
                    <ToastContainer/>
                    <MyRouter/>
                    </Elements>
            </div>
        );
}

export default App;
