import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import axios from "axios";
import GoogleMapReact from "google-map-react";
import { useTranslation } from "react-i18next";
import LocationMarker from "../../components/AnyReactComponent/LocationMarker";
import Loading from "../../components/Loading/Loading";
import { CISUI } from "../../utils/CISUI";
import config from "../../utils/config";

// @ts-ignore
import DOMPurify from "dompurify";
import {Email} from "@material-ui/icons";

export interface AllPage {
  className?: string;
}

interface InfoTypes {
  address: string;
  email: string;
  phone: string;
}

export interface itemContactUs {
  image: string;
  name: string;
  body: string;
  keyword: string;
}

export interface contactInterface {
  image: string;
  name: string;
  details: string;
  title: string;
  subtitle: string;
  ar_subtitle: string;
  keyword: string;
  id: any;
}
const PageContact: FC<AllPage> = ({ className = "" }) => {
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState<contactInterface>();
  const [email, setEmail] = useState<contactInterface>();
  const [phone, setPhone] = useState<contactInterface>();
  const [social, setSocial] = useState<contactInterface[]>();
  const {t} = useTranslation();

  const sectionHowitWorks = () => {
    axios
      .get(config.apiserver + "widgets?page_name=Contact_Section")
      .then(function (response) {
        const allData = response.data.data;
        const contactData = allData.filter((item:any)=> item?.keyword === "contact" )
        setPhone(contactData[0]);
        setEmail(contactData[1]);
        setAddress(contactData[2]);
        // console.log("Contact_Section", contactData);
        const contactLink = allData.filter((item:any)=> item?.keyword === "social_link" )
        setSocial(contactLink);
        console.log("Contact_Section", contactLink);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  useEffect(() => {
    sectionHowitWorks();
  }, []);

  const createMarkup = (html: any) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };
  let lt = "29.3677836";
  let lg = "47.9773467";


  return  loading ? <Loading height={600} /> : (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Safari House || Welcome to Safari House Suite Kuwait</title>
      </Helmet>

      <div className="mb-5 lg:mb-32">
        <h2 className="mt-16 sm:mt-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {" "}
          {t("contact")}
        </h2>
        <div className="container max-w-8xl mx-auto align-middle mt-4">
          <div className="  flex items-center justify-center  py-7 px-5 border content-center ">
            <div className="text-center ">
              <div className="text-2xl font-semibold mb-5 uppercase ">
                {t("Feel free to connect with us")}
              </div>
              <div className=" mb-2">
                <span className="text-xl font-bold">{t("Email")} :</span>{" "}
                <span className="text-xl font-semibold">
                  {email?.subtitle}
                </span>
              </div>
              <div className="capitalize mb-2">
                <span className="text-xl font-bold">{t("Phone Number")}</span>{" "}
                <span className="text-xl font-semibold">{phone?.subtitle}</span>
              </div>
              <div className="text-center flex justify-center space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300">
                {
                  social?.map((item:any)=>
                      <a href={item?.url} target="_blank" rel="noopener noreferrer">
                        <i className={`lab  ${item.subtitle} text-primary-6000 dark:text-neutral-300`}></i>
                      </a>
                  )
                }
              </div>
            </div>


          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      <div className="container mb-10">
        <div className="listingSection__wrap rounded-[40px] my-8 p-6 bg-neutral-100 dark:bg-black dark:bg-opacity-20">
          <div className="text-center">
            <h2 className="text-2xl font-semibold">
              {t("Find out on the map")}
            </h2>
            <div className="container mt-5 mb-8 flex justify-between">

            </div>

            <span className="block  text-neutral-500 dark:text-neutral-400">
              <i className="las la-map-marker-alt mr-1"></i>
              <span
                  dangerouslySetInnerHTML={createMarkup(
                      localStorage.getItem("i18nextLng") === "ar"
                          ? address?.ar_subtitle
                          : address?.subtitle
                  )}
              />
            </span>
          </div>


          <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
            <div className="rounded-xl overflow-hidden">
              {lt !== "" ? (
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyDxJaU8bLdx7sSJ8fcRdhYS1pLk8Jdvnx0",
                  }}
                  defaultZoom={15}
                  yesIWantToUseGoogleMapApiInternals
                  defaultCenter={{
                    lat: parseFloat(lt),
                    lng: parseFloat(lg),
                  }}
                >
                  <LocationMarker lat={parseFloat(lt)} lng={parseFloat(lg)} />
                </GoogleMapReact>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageContact;
