const config = {
  // roomurl: "https://portal.safarihousehotel.com/api/?rf=roomlist",
  roomurl: "https://safarihotel.creativeitsoft.com/api/?rf=roomlist",
  baseurl: "https://e2m.creativeitsoft.net/",
  apiserver: "https://www.safarihouseapi.datalibrary.io/api/",
  imageURL: "https://www.safarihouseapi.datalibrary.io/",


  // https://safarihouseapi.datalibrary.io/public//upload/cms/widget/1177639ec93844b14.jpeg
  stripe_key:
    "pk_live_51K9W10BSzlec9v53J7r4xK8IVTS203ZvepTqb2yLRAVVNK6j8aU9Q2fSxj26uZHAMVyh1YSELZQzdLSOhuBgvjH900WfmjSu6d",
};
export default config;
