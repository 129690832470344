import React, { FC } from 'react'
import loader from 'images/loading.gif'

const Loading = (props:{ height?: number }) => {
    return (
        <div className='flex items-center justify-center w-full z-50' style={{minHeight:`${props.height}px`}}>
            <img src={loader} alt="Loading..." className='w-28 z-10'/>
        </div>
    )
}

export default Loading

