import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
// import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
// import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import React, {FC, useEffect, useState} from "react";
// import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
import SectionHowItWork from "../../components/SectionHowItWork/SectionHowItWork";

import HIW1img from "images/icons/HIW-1.png";
import HIW2img from "images/icons/HIW-2.png";
import HIW3img from "images/icons/HIW-3.png";
import HIW1imgDark from "images/icons/HIW-1-dark.png";
import HIW2imgDark from "images/icons/HIW-2-dark.png";
import HIW3imgDark from "images/icons/HIW-3-dark.png";
// import Heading from "../../components/Heading/Heading";
import SectionOurFeatures from "../../components/SectionOurFeatures/SectionOurFeatures";
import rightImgPng from "../../images/Image templete-01.png";
import rightImgPng2 from "../../images/accommodation.jpg";
// import { TaxonomyType } from "data/types";
// import { Link } from "react-router-dom";
// import convertNumbThousand from "utils/convertNumbThousand";
// import NcImage from "shared/NcImage/NcImage";
import SectionSliderNewCategories from "../../components/SectionSliderNewCategories/SectionSliderNewCategories";
import axios from "axios";
import config from "../../utils/config";
import {heroInterface} from "../../components/SectionHero2/SectionHero2";
// @ts-ignore
import DOMPurify from 'dompurify';
import Loading from "../../components/Loading/Loading";
// import Heading from "components/Heading/Heading";
export interface ListingStayPageProps {
  className?: string;
}

const Accommodation: FC<ListingStayPageProps> = ({ className = "" }) => {
  const [loading, setLoading] = useState(false)
  const[hero,setHero]=useState<heroInterface>();
  useEffect(() => {
    heroAllData();
  }, []);

  const heroAllData = () =>{
    setLoading(true)
    axios.get(config.apiserver + 'widgets?page_name=Accommodation')
        .then(function (response) {
          const allData = response.data.data;
          console.log( "look " , response.data.data);
          setHero(allData[0]);
          setLoading(false)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          setLoading(false)
        })
  }
  const createMarkup = (html: any) => {
    return {
      __html: DOMPurify.sanitize(html)
    }
  }
  return (
      loading ? <Loading /> :
              <div
                className={`nc-ListingStayPage relative overflow-hidden ${className}`}
                data-nc-id="Accommodation"
              >
                <Helmet>
                  <title>Easy 2 Manage (E2M) || Service Accommodation Specialist</title>
                </Helmet>
                <BgGlassmorphism />

                <div className="container relative overflow-hidden">
                  {/* SECTION HERO */}
                  <div
                      className={`nc-SectionHeroArchivePage flex flex-col relative`}
                      data-nc-id="SectionHeroArchivePage"
                  >
                    <div className="flex flex-col lg:flex-row lg:items-center">
                      <div
                          className={`pt-10 pb-2 lg:pb-10 lg:pt-16 flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-6 lg:space-y-10 pb-14 lg:pb-32 xl:pb-64 xl:pr-14 lg:mr-10 xl:mr-0`}>
                        <h2 className="font-medium text-2xl md:text-3xl xl:text-4xl leading-[110%]">
                          {/*{coverTitle}*/}
                          {hero?.name}
                        </h2>
                        <div className="flex items-center text-justify md:text-lg text-neutral-500 dark:text-neutral-400">
                                  <span className="ml-2.5" dangerouslySetInnerHTML={createMarkup(hero?.body)}>
                                      {/*{subTitle}*/}

                                  </span>
                        </div>
                        <div className="flex items-center text-justify md:text-lg text-neutral-500 dark:text-neutral-400">
                          <span className="ml-2.5">
                            {/*{subTitle2}*/}
                          </span>
                        </div>

                      </div>

                      <div className="flex-grow lg:-mt-40 xl:-mt-56">
                        <img className="w-full rounded-3xl" src={config.imageURL+"public"+hero?.image} alt="hero"/>
                      </div>
                    </div>

                    {/*<div className="flow-root w-full">*/}
                    {/*    <div className="z-10 lg:-mt-40 xl:-mt-56 w-full">*/}
                    {/*        <HeroSearchForm currentPage={currentPage} currentTab={currentTab}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                  </div>
                  {/*<SectionHeroArchivePage*/}
                  {/*  currentPage="Stay"*/}
                  {/*  currentTab="Stay"*/}
                  {/*  className="pt-10 pb-2 lg:pb-10 lg:pt-16 "*/}
                  {/*  coverTitle='Serviced Accommodation'*/}
                  {/*  subTitle='Easy 2 manage is one of the best companies who offers services of property management and serviced accommodation. PropertyInfo management by easy 2 manage is done by well-trained property managers who take responsibility of your properties. PropertyInfo management by easy 2 manage is done on both domestic and commercial level and it includes apartments as well.'*/}
                  {/*  subTitle2='Easy 2 manage is here to secure and manage your property and it has gained a reputation among the other competitors. Serviced accommodation and property management is not more a stressful thing due to the existence of Easy 2 Manage. As the name of the company shows that we can manage your property very easily and in a more precise and good way.'*/}
                  {/*  imagePng={rightImgPng2}*/}
                  {/*/>*/}

                  {/* SECTION 2 */}
                  <SectionHowItWork className={'lg:pb-32'}
                    title="Why Choose Us"
                    desc=''
                    data={[
                      {
                        id: 1,
                        img: HIW1img,
                        imgDark: HIW1imgDark,
                        title: "Our Focus",
                        desc: "Our main focus is to provide ease, comfort and privacy to our customers. Easy 2 manage offers fantastic hospitality and has all the amenities like home. Our apartments are fully furnished and comfortable",
                      },
                      {
                        id: 2,
                        img: HIW2img,
                        imgDark: HIW2imgDark,
                        title: "Our Team",
                        desc: "Our trained team of property managers takes complete responsibility of your property. Easy 2 manage optimize the income of clients. Our team helps you and provides solution that help you grow your business in more efficient ways. ",
                      },
                      {
                        id: 3,
                        img: HIW3img,
                        imgDark: HIW3imgDark,
                        title: "Our Why",
                        desc: "Easy 2 manage is passionate about bringing excellence. We have 100’s of luxurious serviced apartments, apart hotels, holiday houses and short term to choose from.",
                      },
                    ]}
                  />

                  {/* SECTION 1 */}
                  {/*<div className="relative py-16 text-center">*/}
                  {/*  */}

                  {/*  /!* <div*/}
                  {/*    className={`nc-BackgroundSection absolute inset-y-0 w-screen xl:max-w-[1340px] 2xl:max-w-screen-2xl left-1/2 transform -translate-x-1/2 xl:rounded-[40px] z-0 bg-neutral-100 dark:bg-black dark:bg-opacity-20`}*/}
                  {/*  >*/}
                  {/*  </div> *!/*/}

                  {/*<div>*/}
                  {/*<h2 className={`sm:text-3xl text-3xl md:text-4xl font-semibold mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50`}>*/}
                  {/*    Explore Our Services*/}
                  {/*  </h2>*/}

                  {/*  <ul className="flex  flex-col lg:flex-row justify-between items-center">*/}
                  {/*  /!* <BackgroundSection /> *!/*/}
                  {/*    {DEMO_CATS.map((item, index) => (*/}
                  {/*      <li key={index} style={{ width: '230.4px', marginRight: '16px' }}>*/}
                  {/*        <Link*/}
                  {/*          to={item.href}*/}
                  {/*          className={`nc-CardCategory3 flex flex-col ${className}`}*/}
                  {/*          data-nc-id="CardCategory3"*/}
                  {/*        >*/}
                  {/*          <div*/}
                  {/*            className={`flex-shrink-0 relative w-full aspect-w-5 aspect-h-4 sm:aspect-h-7 h-0 rounded-2xl overflow-hidden group`}*/}
                  {/*          >*/}
                  {/*            <NcImage*/}
                  {/*              src={item.thumbnail}*/}
                  {/*              className="object-cover w-full h-full rounded-2xl"*/}
                  {/*            />*/}
                  {/*            <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>*/}
                  {/*          </div>*/}
                  {/*          <div className="mt-4 truncate mb-4">*/}
                  {/*            <h2*/}
                  {/*              className={`text-base sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium truncate`}*/}
                  {/*            >*/}
                  {/*              {item.name}*/}
                  {/*            </h2>*/}
                  {/*            <span*/}
                  {/*              className={`block mt-2 text-sm text-neutral-6000 dark:text-neutral-400`}*/}
                  {/*            >*/}
                  {/*              {convertNumbThousand(item.count || 0)} properties*/}
                  {/*            </span>*/}
                  {/*          </div>*/}
                  {/*        </Link>*/}
                  {/*      </li>*/}
                  {/*    ))}*/}
                  {/*  </ul>*/}
                  {/*</div>*/}
                  {/*</div>*/}
                  <div className="relative py-16 mb-8">
                    <BackgroundSection />
                    <SectionSliderNewCategories
                        // heading="Explore by types of stays"
                        // subHeading="Explore houses based on 10 types of stays"
                        // categoryCardType="card5"
                        // itemPerRow={5}
                        // sliderStyle="style2"
                        id='1'
                    />
                  </div>
                  {/* SECTION 6 */}
                  <SectionOurFeatures type="type2" rightImg={rightImgPng} />

                </div>
              </div>
  );
};

export default Accommodation;
