import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
// import Badge from "shared/Badge/Badge";
import axios from "axios";
import config from "utils/config";
import Heading from "../../shared/Heading/Heading";
// import ButtonPrimary from "../../shared/Button/ButtonPrimary";
import BackgroundSection from "../../components/BackgroundSection/BackgroundSection";
import HeroRealEstateSearchForm from "../../components/HeroSearchForm/HeroRealEstateSearchForm";
import GallerySlider from "../../components/GallerySlider/GallerySlider";
// import {DateRage} from "../../components/HeroSearchForm/StaySearchForm";
import moment from "moment";
import Loading from "components/Loading/Loading";

let PHOTOS: string[] = [];

const SearchList = (): JSX.Element => {
    const [loading, setLoading] = useState(false)
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const location = urlParams.get('l')
    const startDate = urlParams.get('sd')
    const endDate = urlParams.get('ed')
    const adultGuest: any = urlParams.get('ag')
    const childGuest: any = urlParams.get('cg')
    const infantGuest: any = urlParams.get('ig')
    const date = {
        startDate: moment(startDate, "MM-DD-YYYY"),
        endDate: moment(endDate, "MM-DD-YYYY"),
    }
    const guest = {
        guestAdults: parseInt(adultGuest),
        guestChildren: parseInt(childGuest),
        guestInfants: parseInt(infantGuest)
    }
    console.log(guest);
    console.log(location);



    const [rooms, setRooms] = useState([])
    const getRooms = (() => {
        setLoading(true)
        axios.get(config.apiserver + 'roomlist')
            .then(function (response) {
                setRooms(response.data.data)
                setLoading(false)
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                setLoading(false)
            })
    })
    useEffect(() => {
        getRooms()
    }, [])



    const content = (id: number, property_name: string, bed_room: string, bathroom: string, size: string, price: string, images: any[], is_featured: number, discount: number, address: string, latitude: string, longitude: string): JSX.Element => {
        {
            PHOTOS = [];
            images.map(({ room_id, image }) =>
                // @ts-ignore
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                room_id === id ? PHOTOS.push(config.imageURL + image) : ''
            )
        }
        return (
            <div
                className={`nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800  overflow-hidden hover:shadow-xl transition-shadow h-full`}
                data-nc-id="PropertyCardH"
            // key={id}
            >
                <Link
                    to={`/property-details/${id}`}
                    className="w-full flex flex-col sm:flex-row sm:items-center"
                >
                    <div className="flex-shrink-0 p-3 w-full h-full sm:w-64 ">
                        {/*{console.log(PHOTOS)}*/}
                        <GallerySlider
                            ratioClass="aspect-w-1 aspect-h-1"
                            galleryImgs={PHOTOS}
                            className="w-full h-full  overflow-hidden"
                            uniqueID={`PropertyCardH-${Date.now()}-${id}`}
                        />
                        {/*
                          {saleOff && (
                            <SaleOffBadge className="absolute left-5 top-5 !bg-orange-500" />
                          )} */}
                        {discount === 0 ? '' : (<div
                            className={`nc-SaleOffBadge flex items-center justify-center text-xs py-0.5 px-3 text-red-50  absolute left-5 top-5 bg-orange-500`}
                            data-nc-id="SaleOffBadge"
                        >
                            {'-'} {discount} {'%'}
                        </div>)}
                    </div>
                    <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
                        <div className="space-y-4 w-full">
                            <div className="flex items-center space-x-2">
                                {/*<Badge name="ADS" color="green"/>*/}
                                <h2 className="text-lg font-medium capitalize">
                                    <span className="line-clamp-2">{property_name}</span>
                                </h2>
                            </div>
                            <div className="flex items-center space-x-4">
                                {/*<StartRating/>*/}
                                {/*<span>·</span>*/}
                                <i className="las la-map-marker-alt"></i>
                                <span className="ml-1">{address}</span>

                            </div>
                            <div className="inline-grid grid-cols-3 gap-2">
                                <div className="flex items-center space-x-2">
                                    <span className="hidden sm:inline-block">
                                        <i className="las la-bed text-lg" />
                                    </span>
                                    <span className="text-xs text-neutral-500 dark:text-neutral-400">
                                        {bed_room} beds
                                    </span>
                                </div>

                                <div className="flex items-center space-x-2">
                                    <span className="hidden sm:inline-block">
                                        <i className="las la-bath text-lg" />
                                    </span>
                                    <span className="text-xs text-neutral-500 dark:text-neutral-400">
                                        {bathroom} baths
                                    </span>
                                </div>

                                {/* ---- */}
                                <div className="flex items-center space-x-2">
                                    <span className="hidden sm:inline-block">
                                        <i className="las la-expand-arrows-alt text-lg" />
                                    </span>
                                    <span className="text-xs text-neutral-500 dark:text-neutral-400">
                                        {size} Sq. Fit
                                    </span>
                                </div>
                            </div>
                            <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 "></div>
                            <div className="flex w-full justify-between items-end">
                                <span
                                    className="flex items-center justify-center px-3 py-2 border border-secondary-500 rounded leading-none text-base font-medium text-secondary-500">
                                    KD{price}
                                </span>
                            </div>
                        </div>
                    </div>
                </Link>
                {/*<BtnLikeIcon*/}
                {/*    colorClass={`bg-neutral-100 dark:bg-neutral-700 hover:bg-neutral-200 hover:bg-opacity-70 text-neutral-6000 dark:text-neutral-400`}*/}
                {/*    isLiked={false}*/}
                {/*    className="absolute right-5 top-5 sm:right-3 sm:top-3 "*/}
                {/*/>*/}
            </div>
        );
    }

    return (
        loading ? <Loading /> :
            <>
                <div className="container relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
                    <div className="relative py-16"><BackgroundSection />
                        <div className="nc-SectionGridFeatureProperty relative">
                            <div className="flex flex-col relative">
                                {console.log(date)}
                                <HeroRealEstateSearchForm className="mb-10" location={location} date={date} guest={guest} />
                                <Heading
                                    desc={""}>{"Search Result :"}</Heading>
                            </div>
                            <div className={`grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-2`}>
                                {rooms.map(({
                                    id,
                                    property_name,
                                    bed_room,
                                    size,
                                    name,
                                    details,
                                    balcony,
                                    bathroom,
                                    facilities,
                                    kitchen,
                                    other_room,
                                    price,
                                    discount,
                                    room_type,
                                    images,
                                    is_featured,
                                    address,
                                    latitude,
                                    longitude
                                }, index) => (
                                    content(id, property_name, bed_room, bathroom, size, price, images, is_featured, discount, address, latitude, longitude)
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>

    );
};

export default SearchList;
