import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import React, {useEffect, useState} from "react";
import appSvg1 from "images/appSvg1.png";
import appSvg2 from "images/appSvg2.png";
import appRightImgTree from "images/appRightImgTree.png";
import dowloadAppBGPng from "images/dowloadAppBG.png";
import appRightImg from "images/appRightImg.png";
import btnIosPng from "images/btn-ios.png";
import btnAndroidPng from "images/btn-android.png";
import androidQR from 'images/androidQR.svg';
import iosQR from 'images/iosQR.svg';
import {itemBennefits} from "../../components/SectionOurFeatures/SectionOurFeatures";
import axios from "axios";
import config from "../../utils/config";
import Loading from "../../components/Loading/Loading";

export interface  itemMobaileApp{
  image: string;
  name: string;
  body:string;
  keyword:string;
}
const SectionDowloadApp = () => {
  const [loading, setLoading] = useState(false);
  const[mobailIntroDes,setMobailIntroDes]=useState<itemMobaileApp>();
  const[mobailIntroCommingSoon,setMobailIntroCommingSoon]=useState<itemMobaileApp>();
  const[appleQrCode,setAppleQrCode]=useState<itemMobaileApp>();
  const[androidQrCode,setAndroidQrCode]=useState<itemMobaileApp>();


  const sectionMobailApps= () =>{
    setLoading(true)
    axios.get(config.apiserver + 'widgets?page_name=Home')
        .then(function (response) {
          const allData = response.data.data;
          console.log( "look section " , response.data.data);
          setMobailIntroDes(allData[14]);
          setMobailIntroCommingSoon(allData[15]);
          setAppleQrCode(allData[16]);
          setAndroidQrCode(allData[17]);
          setLoading(false)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          setLoading(false)
        })
  }
  useEffect(() => {
    sectionMobailApps();
  }, []);
  const createMarkup = (html: any) => {
    return {
      // __html: DOMPurify.sanitize(html)
    }
  }
  return (
      loading ? <Loading /> :
    <div className="relative pb-0 pt-24 lg:py-32 xl:py-40 2xl:py-48">
      <BackgroundSection className="bg-neutral-100 bg-opacity-80 dark:bg-opacity-100 ">
        <img
          className="absolute inset-0 w-full h-full object-cover rounded-3xl object-right"
          src={dowloadAppBGPng}
          alt="dowloadAppPng"
        />

        <div className="hidden lg:block absolute right-0 bottom-0 max-w-xl xl:max-w-2xl rounded-3xl overflow-hidden">
          <img src={appRightImg} alt="" />
        </div>
        <div className="absolute right-0 top-0 max-w-2xl">
          <img src={appRightImgTree} alt="" />
        </div>
        <div className="absolute left-0 bottom-10 max-w-2xl">
          <img src={appSvg1} alt="" />
        </div>
      </BackgroundSection>

      <div className="relative inline-block ">
        <h2 className="text-5xl md:text-6xl xl:text-7xl font-bold text-neutral-800">
          Mobile Apps
        </h2>
        <span className="block mt-7 max-w-md text-neutral-6000">
           {mobailIntroDes?.name}
        </span>
        <div className="flex space-x-3 mt-5 sm:mt-5">
          <a href="##" className="text-center" target="_blank" rel="noopener noreferrer">
            <img className='mb-2' src={config.imageURL+"public"+appleQrCode?.image} alt="android QR" style={{width:'180px', height:'180px'}}/>
            <img src={btnIosPng} alt="" />
          </a>
          <a href="##" target="_blank" rel="noopener noreferrer">
            <img className='mb-2' src={config.imageURL+"public"+androidQrCode?.image} alt="iOS QR" style={{width:'180px', height:'180px'}}/>
            <img src={btnAndroidPng} alt="" />
          </a>
        </div>
        <div className="mt-8 ml-8">
          <span className="uppercase text-3xl text-gray-700 tracking-widest font-black"> {mobailIntroCommingSoon?.name}</span>
        </div>

        <img
          className="hidden lg:block absolute lg:left-full lg:top-0 xl:top-1/2 z-10  lg:max-w-sm 2xl:max-w-none"
          src={appSvg2}
          alt=""
        />

        <div className="block lg:hidden mt-10 max-w-2xl rounded-3xl overflow-hidden">
          <img src={appRightImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default SectionDowloadApp;
