import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import axios from "axios";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import config from "utils/config";
// @ts-ignore
import DOMPurify from "dompurify";
import AnimateButton from "shared/Button/AnimateButton";
import ReactModalVideo from "./ModalVideo/ReactModalVideo";
import ReactModalVideoPlayer from "./ReactModalVideoPlayer";
//@ts-ignore
import BG from "https://img.freepik.com/free-vector/hand-painted-watercolor-pastel-sky-background_23-2148902771.jpg?w=2000";
import Loading from "components/Loading/Loading";
export interface heroIntro {
  image: string;
  name: string;
  details: string;
  ar_details: string;
  title: string;
  ar_title: string;
  subtitle: string;
  ar_subtitle: string;
  keyword: string;
  id: any;
}
export interface AllPage {
  className?: string;
}
// const marked = window.marked;
declare global {
  interface Window {
    marked: any;
  }
}

function YouTubeGetID(url:any){
  url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
}




const HomeIntro: FC<AllPage> = ({ className = "" }) => {
  let marked = window.marked; // ok now
  const [roomIntroArea, setRoomIntroArea] = useState<heroIntro>();
  const [introAreaVideo  , setIntroAreaVideo] = useState<heroIntro>();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const roomIntro = () => {
    // setLoading(true);
    axios
        .get(config.apiserver + "widgets?page_name=Hotel_Intro")
        .then(function (response) {
          const allData = response.data.data;
          const roomIntroData = allData.find((item:any)=> item?.keyword === "Room_Intro" )
          const IdYoutube = YouTubeGetID(roomIntroData?.url)
          setIntroAreaVideo(IdYoutube)
          setRoomIntroArea(roomIntroData);
          // console.log("Robin",roomIntroData)
          setLoading(false);
        })
        .catch(function (error) {

        });
  };
  useEffect(() => {
      roomIntro();
  }, []);
  const createMarkup = (html: any) => {
    return {
      __html: marked.parse(DOMPurify.sanitize(html)),
    };
  };

  return  loading ? <Loading height={700} />: (
    <div className="location__intro p__50 ">
      <div className="left__intro ">
        <div className="small__intro text-lg font-normal text-neutral-900 dark:text-neutral-100 tracking-wide">
          <p>
            <span
                dangerouslySetInnerHTML={createMarkup(
                    localStorage.getItem("i18nextLng") === "ar"
                        ? roomIntroArea?.ar_subtitle
                        : roomIntroArea?.subtitle
                )}
            />
          </p>
        </div>
        <div
          className="title__intro text-3xl font-semibold text-neutral-900 md:text-3xl
         xl:text-3xl dark:text-neutral-100 tracking-wide"
        >
          <span
            dangerouslySetInnerHTML={createMarkup(
              localStorage.getItem("i18nextLng") === "ar"
                ? roomIntroArea?.ar_title
                : roomIntroArea?.title
            )}
          />
          {/* {locationArea?.title} */}
        </div>
        <div className="detail__intro text-justify">
          <p>
             <span
              dangerouslySetInnerHTML={createMarkup(localStorage.getItem("i18nextLng") === "ar"
                  ? roomIntroArea?.ar_details.replace(/(<([^>]+)>)/gi, "")
                  :roomIntroArea?.details.replace(/(<([^>]+)>)/gi, "")
              )}
            />
          </p>
          <div className="w-full flex justify-start pt-5">
            <AnimateButton
              link={"/about"}
              type="button"
              btnTitle={t("more info ...")}
            />
          </div>
        </div>
      </div>
      <div className="right__intro"
           style={{
             backgroundImage: `url(${config.imageURL + "public/" +   roomIntroArea?.image})`,
           }}
      >
          {console.log("introAreaVideo", introAreaVideo)}
        <ReactModalVideoPlayer youtubeId={introAreaVideo !== undefined && introAreaVideo} url={introAreaVideo}/>

      </div>
    </div>
  );
};

export default HomeIntro;
