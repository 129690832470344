import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import axios from "axios";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import config from "../../../utils/config";

// @ts-ignore
import DOMPurify from "dompurify";
//@ts-ignore
import NcImage from "shared/NcImage/NcImage";
import Loading from "../../../components/Loading/Loading";
export interface internationalArea {
  image: string;
  name: string;
  body: string;
  title: string;
  ar_title: string;
  subtitle: string;
  ar_subtitle: string;
  details: string;
  ar_details: string;
  keyword: string;
  id: any;
}
export interface AllPage {
  className?: string;
}
declare global {
  interface Window {
    marked: any;
  }
}
const HomeCompareInternational: FC<AllPage> = ({ className = "" }) => {
  let marked = window.marked; // ok now
  const [internationalDes, setInternationalDes] = useState<internationalArea>();
  const [internationalRooms, setInternationalRooms] =
    useState<internationalArea[]>();
  const [loading, setLoading] = useState(false);
  const [lang, setLang] = useState("en-US");
  const [toggle, setToggle] = useState(true);
  const { t } = useTranslation();

  const sectionInternational = () => {
    setLoading(true);
    axios
      .get(config.apiserver + "widgets?page_name=International_Type")
      .then(function (response) {
        const allData = response.data.data;
        const roomDesData = allData.filter(
          (item: any) => item?.keyword === "internatioin_key_title"
        );
        setInternationalDes({ ...roomDesData[0] });
        const interRoomData = response.data.data.filter(
          (item: any) => item?.keyword === "internatioin_key_info"
        );
        setInternationalRooms(interRoomData);
        console.log("image", interRoomData?.image);
        console.log("inter===", interRoomData);
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    sectionInternational();
  }, []);

  const createMarkup = (html: any) => {
    return {
      __html: marked.parse(DOMPurify.sanitize(html)),
    };
  };
  console.log("internationalRooms====", internationalRooms);
  // @ts-ignore
  return loading ? <Loading height={800} />:  (
    <>
      <div className="">
        <div className="left__intro info__des">
          <div
            className="text-3xl font-semibold text-neutral-900 md:text-3xl xl:text-3xl dark:text-neutral-100 tracking-wide"
            dangerouslySetInnerHTML={createMarkup(
              localStorage.getItem("i18nextLng") === "ar"
                ? internationalDes?.ar_title
                : internationalDes?.title
            )}
          ></div>
          <div className="dark:text-neutral-400 text-justify tracking-wide mt-7 detail__intro">
            <p
              className="text-3xl font-semibold text-neutral-900 md:text-3xl xl:text-3xl dark:text-neutral-100 tracking-wide"
              dangerouslySetInnerHTML={createMarkup(
                localStorage.getItem("i18nextLng") === "ar"
                  ? internationalDes?.ar_subtitle
                  : internationalDes?.subtitle
              )}
            ></p>
          </div>
        </div>
        <div className="international__gallery ">
          {internationalRooms?.map((item: any) => (
            <div className="item__international item1" key={item.id}>
              <NcImage
                src={config.imageURL + "public/" + item?.image}
                alt=""
                className="inter__image"
              />

              <div className="inter__info">
                <h2>
                  {localStorage.getItem("i18nextLng") === "ar"
                    ? item?.ar_title
                    : item?.title}
                </h2>
                <p>
                  {localStorage.getItem("i18nextLng") === "ar"
                    ? item?.ar_subtitle
                    : item?.subtitle}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default HomeCompareInternational;
