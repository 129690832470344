import React, { HTMLAttributes, ReactNode } from "react";
import NextPrev from "shared/NextPrev/NextPrev";
// @ts-ignore
import DOMPurify from 'dompurify';
import Loading from "../Loading/Loading";
import {useTranslation} from "react-i18next";
export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  fontClass?: string;
  desc?: ReactNode;
  hasNextPrev?: boolean;
  isCenter?: boolean;
}

const Heading: React.FC<HeadingProps> = ({
  children,
  desc = "",
  className = "mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50",
  isCenter = false,
  hasNextPrev = false,
  ...args
}) => {
  const {t} = useTranslation()
  const createMarkup = (html: any) => {
    return {
      __html: DOMPurify.sanitize(html)
    }
  }
  return (
        <div className={`nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between ${className}`} >
      <div className={  isCenter ? "text-center w-full max-w-2xl mx-auto" : "w-auto"  }  >
        <h2 className={`sm:text-3xl text-3xl md:text-4xl font-semibold`} {...args}>   { children || `Section Heading`} </h2>

        {desc && (
          <p className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400 sm:mr-3 w">
            <span dangerouslySetInnerHTML={createMarkup(desc)}></span>
          </p>
        )}
      </div>
      {hasNextPrev && !isCenter && (
        <div className="mt-4 flex justify-end sm:ml-2 sm:mt-0 flex-shrink-0">
          <NextPrev onClickNext={() => {}} onClickPrev={() => {}} />
        </div>
      )}
    </div>
  );
};

export default Heading;
