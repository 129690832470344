import { FC } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
// import SearchDropdown from "./SearchDropdown";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import LanguageDropdown from "./LanguageDropdown";

export interface MainNav1Props {
  isTop: boolean;
}

const MainNav1: FC<MainNav1Props> = ({ isTop }) => {
  let customer = "customer";
  let owner = "owner";
  let access = localStorage.getItem("access_token");
  // console.log(access)
  let history = useHistory();
  const { t } = useTranslation();

  const Logout = () => {
    let type = localStorage.getItem("login_type");
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
    if (type === "owner") {
      history.push("/login/owner");
      window.location.reload();
    } else {
      history.push("/login/customer");
      window.location.reload();
    }
  };
  const Profile = () => {
    let type = localStorage.getItem("login_type");
    console.log(type);
    if (type === owner) {
      history.push("/owner/my-property");
      window.location.reload();
    } else {
      history.push("/customer/customer-upcoming-booking");
      // window.location.reload();
    }
  };

  // @ts-ignore
  return (
    <div
      className={`nc-MainNav1 relative z-10 border-b dark:border-b dark:border-neutral-700 ${
        isTop ? "onTop " : "notOnTop backdrop-filter"
      }`}
    >
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8 ">
        <div className="flex justify-start flex-grow items-center space-x-4 sm:space-x-10 2xl:space-x-14">
          <Logo />
          <Navigation />
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center space-x-0 xl:flex">
            <SwitchDarkMode />
            {/*<SearchDropdown />*/}
            <LanguageDropdown />

            {access === null ? (
              <>
                <ButtonPrimary href={"/book-now"} type="button">
                  {" "}
                  {t("button_booknow")}
                </ButtonPrimary>
                <div className="px-1" />
                {/* <ButtonPrimary href={"https://partners.easy2manage.co.uk"} type="button" targetBlank>Partners Portal</ButtonPrimary> */}
                {/*<a href="https://partners.easy2manage.co.uk/" target="_blank" rel="noopener noreferrer" className="btn-primary">Partners Portal</a>*/}
              </>
            ) : (
              <>
                <ButtonPrimary  href={"/book-now"} onClick={Profile} type="button">
                  {t("button_booknow")}
                </ButtonPrimary>
                <div className="px-1" />
                {/*<ButtonPrimary onClick={Logout} type="button">*/}
                {/*  Logout*/}
                {/*</ButtonPrimary>*/}
              </>
            )}
          </div>


          <div className="flex items-center xl:hidden">
            <SwitchDarkMode />
            <LanguageDropdown />
            <div className="hidden lg:flex items-center space-x-0 xl:flex">
              {access === null ? (
                <>
                  <ButtonPrimary href={"/book-now"}  type="button">
                    {t("button_booknow")}
                  </ButtonPrimary>
                  <div className="px-1" />
                  {/*<ButtonPrimary href={`https://partners.easy2manage.co.uk/`} type="button" targetBlank>Partners Portal</ButtonPrimary>*/}
                </>
              ) : (
                <>
                  <ButtonPrimary href={"/book-now"}  onClick={Profile} type="button">
                    {t("button_booknow")}
                  </ButtonPrimary>
                  <div className="px-1" />
                  {/*<ButtonPrimary onClick={Logout} type="button">*/}
                  {/*  Logout*/}
                  {/*</ButtonPrimary>*/}
                </>
              )}
            </div>
            <div className="px-1" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
