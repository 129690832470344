import React, { HTMLAttributes, ReactNode } from "react";

// import {Tab} from "@headlessui/react";
// import {DEMO_CAR_LISTINGS, DEMO_EXPERIENCES_LISTINGS, DEMO_STAY_LISTINGS} from "../../data/listings";
// import StayCard from "../StayCard/StayCard";
// import ButtonSecondary from "../../shared/Button/ButtonSecondary";
// import ExperiencesCard from "../ExperiencesCard/ExperiencesCard";
// import CarCard from "../CarCard/CarCard";
import ProfileUpdate from "../../containers/CustomerPortal/ProfileUpdate";
import BookedProperty from "../../containers/CustomerPortal/bookedProperty";
import BookingHistoryCard from "../../containers/CustomerPortal/BookingHistoryCard";
import PaymentHistoryCard from "../../containers/CustomerPortal/PaymentHistoryCard";
import BookingRequestCard from "../../containers/CustomerPortal/BookingRequestCard";

export interface DashboardProps extends HTMLAttributes<HTMLHeadingElement> {
    fontClass?: string;
    desc?: ReactNode;
    hasNextPrev?: boolean;
    isCenter?: boolean;
    pageTitle?: string;
    show?: string
}

const LeftNav: React.FC<DashboardProps> = ({
    children,
    desc = "Popular places to stay that E2M recommends for you",
    className = "mb-12 lg:mb-16 text-neutral-900 dark:text-neutral-50",
    isCenter = false,
    hasNextPrev = false,
    pageTitle = '',
    show,
    ...args
}) => {


    return (
        <div className="listingSection__wrap bg-neutral-100 dark:bg-black dark:bg-opacity-20 p-5 rounded-2xl my-2">
            <div>
                <h2 className="text-2xl font-semibold">{pageTitle}</h2>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                </span>
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            {(show === "profile") ?
                <ProfileUpdate /> :
                (show === "bookingRequest") ?
                    <BookingRequestCard/> :
                        (show === "upcomingBooking") ?
                            <BookedProperty /> :
                            (show === "bookingHistory") ?
                                <BookingHistoryCard /> :
                                (show === "paymentHistory") ?
                                    <PaymentHistoryCard /> : ""

            }
        </div>
    );
};

export default LeftNav;
