import {FC, useEffect, useState} from "react";
import Heading from "shared/Heading/Heading";
import NcImage from "shared/NcImage/NcImage";
import NcPlayIcon from "shared/NcPlayIcon/NcPlayIcon";
import NcPlayIcon2 from "shared/NcPlayIcon2/NcPlayIcon2";
import {useTranslation} from "react-i18next";
import axios from "axios";
import config from "../../utils/config";

export interface VideoType {
  id: number;
  title: string;
  image: string;
}

export interface SectionVideosProps {
  videos?: VideoType[];
  className?: string;
}

const VIDEOS_DEMO = [
  {
    id: "NcRifDitRnU",
    title: "Magical Scotland - 4K Scenic Relaxation Film with Calming Music",
    thumbnail:
    "https://ak-d.tripcdn.com/images/220112000000tl90tC66A.jpg"
  },
  {
    id: "a5V6gdu5ih8",
    title: "Magical Scotland - 4K Scenic Relaxation Film with Calming Music",
    thumbnail:
      "https://cf.bstatic.com/xdata/images/hotel/max1024x768/403222383.jpg?k=18f0ce69b9626e287bd94b8be1d3ecc2e07a0f7c7156b1a43a620af50ee943f3&o=&hp=1",
  },
  {
    id: "MuB7HHeuNbc",
    title: "Magical Scotland - 4K Scenic Relaxation Film with Calming Music",
    thumbnail:
      "https://images.pexels.com/photos/1660995/pexels-photo-1660995.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "eEaZvEZye84",
    title: "Magical Scotland - 4K Scenic Relaxation Film with Calming Music",
    thumbnail:
      "https://images.pexels.com/photos/4983184/pexels-photo-4983184.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "EuDJZDaSP0Q",
    title: "Magical Scotland - 4K Scenic Relaxation Film with Calming Music",
    thumbnail:
      "https://images.pexels.com/photos/2549018/pexels-photo-2549018.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
];
function YouTubeGetID(url:any){
  url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
}


const SectionVideos: FC<SectionVideosProps> = ({
  videos = VIDEOS_DEMO,
  className = "",
}) => {
  const {t} = useTranslation();
  const [isPlay, setIsPlay] = useState(false);
  const [allVideos, setAllVideos] = useState([]);
  const [currentVideo, setCurrentVideo] = useState<any>();
  const [loading, setLoading] = useState(false);

  const roomIntro = () => {
    setLoading(true);
    axios
        .get(config.apiserver + "widgets?page_name=Gallery_Page")
        .then(function (response) {

          const allData = response.data.data;
          const roomIntroData = allData.filter((item:any)=> item?.keyword === "video_item" );
          const videoData = roomIntroData?.map((item:any)=>{
            return { ...item, video_id: YouTubeGetID(item?.url) }
          })
          setCurrentVideo(videoData[0])
          setAllVideos(videoData)
          // const IdYoutube=YouTubeGetID(roomIntroData?.url)

          console.log("Robin",videoData)
          setLoading(false);
        })
        .catch(function (error) {

        });
  };
  useEffect(() => {
    roomIntro();
  }, []);
  const renderMainVideo = () => {

    if(currentVideo?.id !== undefined){
        return (
            <div
                className="group aspect-w-16 aspect-h-16 sm:aspect-h-9 bg-neutral-800  overflow-hidden border-4 border-white dark:border-neutral-900  sm:border-[10px]"
                title={currentVideo?.title}
            >
                {isPlay ? (
                    <iframe
                        src={`https://www.youtube.com/embed/${currentVideo?.video_id}?autoplay=1`}
                        title={currentVideo?.title}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                ) : (
                    <>
                        <div
                            onClick={() => setIsPlay(true)}
                            className="cursor-pointer absolute inset-0 flex items-center justify-center z-10"
                        >
                            <NcPlayIcon />
                        </div>
                        <NcImage
                            containerClassName="absolute inset-0 "
                            className="object-cover w-full h-full transform transition-transform group-hover:scale-105 duration-300 nc-will-change-transform"
                            src={`${config.imageURL}public${currentVideo?.image}`}
                            title={t(currentVideo?.title)}
                            alt={t(currentVideo?.title)}
                        />
                    </>
                )}
            </div>
        );
    }
  };

  const renderSubVideo = (video: VideoType, index: number) => {
    // if (index === currentVideo) return null;
    return (
      <div
        className="group relative aspect-h-16 aspect-w-16  cursor-pointer overflow-hidden sm:aspect-h-12  lg:aspect-h-9"
        onClick={() => {
          setCurrentVideo(video);
          // !isPlay && setIsPlay(true);
        }}
        title={video.title}
        key={String(index)}
      >
        <div className="absolute inset-0 flex items-center justify-center z-10">
          <NcPlayIcon2 />
        </div>
        <NcImage
          containerClassName="absolute inset-0 w-full h-full"
          className="object-cover w-full h-full transform transition-transform group-hover:scale-110 duration-300 nc-will-change-transform"
          src={`${config.imageURL}public${video?.image}`}
          title={video.title}
          alt={video.title}
        />
      </div>
    );
  };

  return (
    <div className={`nc-SectionVideos ${className}`}>
      <Heading
          desc={t
          ("Check out our hottest videos. View more and share more new perspectives on just about any topic. Everyone’s welcome.")
          }
      >
        {t("🎬 The Videos")}
      </Heading>

      <div className="flex flex-col relative sm:pr-4 sm:py-4 md:pr-6 md:py-6 xl:pr-14 xl:py-14 lg:flex-row">
        <div className="absolute -top-4 -bottom-4 -right-4 w-2/3  bg-primary-100  z-0  md:top-0 md:bottom-0 md:right-0 xl:w-1/2 dark:bg-neutral-800 dark:bg-opacity-40"></div>

        <div className="flex-grow relative pb-2 sm:pb-4 lg:pb-0 lg:pr-5 xl:pr-6">
          {renderMainVideo()}
        </div>

        <div className="flex-shrink-0 grid gap-2 grid-cols-4 sm:gap-6 lg:grid-cols-1 lg:w-36 xl:w-40">
          {allVideos.map(renderSubVideo)}
        </div>
      </div>
    </div>
  );
};

export default SectionVideos;
