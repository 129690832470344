// import StartRating from "components/StartRating/StartRating";
import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../utils/config";
import moment from "moment";
import Loading from "components/Loading/Loading";
import { CISUI } from "utils/CISUI";
// import { BookingsProps } from "./BookingRequestCard";
// import Tag from "shared/Tag/Tag";
import Badge from "shared/Badge/Badge";
import NcModal from "shared/NcModal/NcModal";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
export interface BookingRequestProps {
	className?: string;
}

const BookingRequestDetails: FC<BookingRequestProps> = ({ className = "" }) => {

	interface BookingsProps {
		check_in?: String
		check_out?: String
		booking_id?: number
		customer_name?: String
		discount_amount?: number
		grand_total?: number
		guest?: number
		image?: String
		property_name?: String
		room_id?: number
		room_no?: String
		status?: number
		total?: number
		total_discount?: number
		request_id?: number
		property_id?: number
		price?: number
		nights?: number
	}

	const stripe = useStripe();
	const elements = useElements();
	const { id } = useParams<{ id?: any }>();
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const guest = urlParams.get('guest')

	const [bookings, setBookings] = useState<BookingsProps>({})
	const [loading, setloading] = useState(false)
	const [btnDisabled, setBtnDisabled] = useState(true)
	const [btnLoading, setBtntLoading] = useState(false)
	const [status, setStatus] = useState("")
	const history = useHistory();
	// const PHOTOS: string[] = [];

	const getRequestDetails = (() => {
		setloading(true)
		axios.get(config.apiserver + `booking/pendingcustomerbookreqdetails/${id}`, CISUI().HeaderRequest)
			.then(function (response) {
				console.log("response.data.data");
				console.log(response.data.data);
				setBookings(response.data.data)
				setloading(false)
				let currentStatus= response.data.data?.status === 0 ? "Request Pending" : response.data.data?.status === 1 ? "Request Accepted." : response.data.data?.status === 2 ? "Request Declined" : response.data.data?.status === 3 ? "Canceled" : ""
				setStatus(currentStatus)
			})
			.catch(function (error) {
				// console.log(error);
				setloading(false)
			})
	})

	const handleCancel = () => {
		// booking/bookingreqcancel
		setloading(true)
		axios.post(config.apiserver + `booking/bookingreqcancel/${id}`, "", CISUI().HeaderRequest)
			.then(function (response) {

				console.log(response);
				// setBookings(response.data.data)
				setloading(false)
				console.log("SUccess");
				setStatus("Canceled")

				// CISUI().notification('success', response.data.msg);
			})
			.catch(function (error) {
				console.log(error);
				setloading(false)
				console.log("error");

				// CISUI().notification('error', error.data.msg);
			})
	}
	useEffect(() => {
		getRequestDetails()
	}, [])

	// const handleMakePayment = () => {

	// 	history.push(`/checkout/${bookings?.room_id}?sd=${moment(`${bookings?.check_in}`).format("MM-DD-YYYY")}&ed=${moment(`${bookings?.check_out}`).format("MM-DD-YYYY")}&ag=${bookings?.guest}&cg=0&ig=0&src=br`)
	// }





	const renderStripe = () => {

		const handleSubmit = async (event: { preventDefault: () => void; }) => {
			setBtntLoading(true)
			event.preventDefault();

			const cardElement = elements!.getElement(CardElement);
			// console.log(cardElement);

			const { error, paymentMethod } = await stripe!.createPaymentMethod({
				type: "card",
				card: cardElement!
			})

			if (!error) {
				// try {
				// const tGuest = parseInt(adultGuest) + parseInt(childGuest) + parseInt(infantGuest)
				const customer: any = localStorage.getItem('user')
				let user = JSON.parse(customer)


				// need property_id and 
				const values = {
					property_id: bookings?.property_id,
					stripeToken: paymentMethod!.id,
					customer_id: user.customer_id,
					guest: bookings?.guest,
					booking_items: [{
						room_id: bookings?.room_id,
						room_no: bookings?.room_no,
						check_in_date: moment(`${bookings?.check_in}`).format("YYYY-MM-DD"),
						check_out_date: moment(`${bookings?.check_out}`).format("YYYY-MM-DD"),
						nights: bookings?.nights,
						customer_price: bookings?.price,
						discount: bookings?.total_discount,
						total: bookings?.grand_total
					}],
					logs: paymentMethod
				}

				const response = await axios.post(config.apiserver + "booking/newbooking", values, CISUI().HeaderRequest)
				if (response.data.status === 1) {
					// console.log(response);

					CISUI().notification('success', response.data.msg)
					setBtntLoading(false)
					history.push("/customer/customer-upcoming-booking")

				}
				else {
					CISUI().notification('error', response.data.msg)
					setBtntLoading(false)
				}

			}
			else {
				let msg: any = error.message;
				CISUI().notification('error', msg);
				setBtntLoading(false)
				// console.log(error.message)
			}

		}
		const handleCheckbox = () => {
			setBtnDisabled(!btnDisabled)
		}
		return (
			<>
				<form onSubmit={handleSubmit}>
					<CardElement className="my-5 border-2 p-5  border-neutral-200 dark:border-neutral-700 dark:bg-white !text-white" />
					<div className={`flex text-sm sm:text-base mb-5`}>
						<input
							name="trams"
							type="checkbox"
							className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
							onChange={handleCheckbox}
						/>

						<label htmlFor="trams" className="ml-3.5 flex flex-row text-neutral-6000 dark:text-neutral-300">I agree to the <Link to="/terms" className="font-semibold ml-1">Terms and Conditions</Link></label>
					</div>
					<div className="flex justify-between">
						<ButtonPrimary disabled={btnDisabled} loading={btnLoading} className={btnDisabled === true ? "cursor-not-allowed" : ""}>Confirm and pay</ButtonPrimary>
						<p className="mt-5 text-gray-400 text-xs">Powered by <b className="text-gray-700 dark:text-white">Stripe</b></p>

					</div>
				</form>
			</>
		)
	}
	const renderContent = () => {
		return (
			loading ? <Loading /> :
				<div className="w-full flex flex-col sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
					{console.log(bookings)}
					{/* ------------------------ */}rounded-3xl
					<div className="space-y-6">
						<div className="flex justify-between items-center">
							<h3 className="text-2xl font-semibold">Booking Request Submitted</h3>
							<Badge className="py-2" name={status} />		
							{/* <Badge className="py-2" name={bookings?.status === 0 ? "Request Pending" : bookings?.status === 1 ? "Request Accepted." : bookings?.status === 2 ? "Request Declined" : bookings?.status === 3 ? "Canceled" : ""} /> */}
						</div>
						<div className="flex flex-col sm:flex-row sm:items-center">
							<div className="flex-shrink-0 w-full sm:w-40">
								<div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4  overflow-hidden">
									<NcImage src={config.imageURL + bookings?.image} />
								</div>
							</div>
							<div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
								<div>
									{/* <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                                        Hotel room in {rDetails?.address}
                                    </span> */}
									<span className="font-semibold mt-1 block text-3xl">
										{bookings?.property_name}
									</span>
								</div>
								<span className="block  text-sm text-neutral-500 dark:text-neutral-400">
									Room No: {bookings?.room_no}
								</span>
							</div>
						</div>
						<div className="mt-6 border border-neutral-200 dark:border-neutral-700  flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
							<div className="flex-1 p-5 flex space-x-4">
								<svg
									className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
									viewBox="0 0 28 28"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
										stroke="#D1D5DB"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>

								<div className="flex flex-col">
									<span className="text-sm text-neutral-400">Date</span>
									<span className="mt-1.5 text-lg font-semibold">
										{/* {moment(rDetails.booking_dates?.[0]?.date).format("DD MMM, YYYY")} {"to"} {moment(rDetails.booking_dates?.[rDetails.booking_dates?.length - 1]?.date).format("DD MMM, YYYY")} */}

										{/*{moment(startDate).format("DD MMM, YYYY")}*/}
										{/*{" to "}*/}
										{/*{moment(endDate).format("DD MMM, YYYY")}*/}
										{moment(`${bookings?.check_in}`).format("ll")} {"to"} {moment(`${bookings?.check_out}`).format("ll")}
									</span>
								</div>
							</div>
							<div className="flex-1 p-5 flex space-x-4">
								<svg
									className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
									viewBox="0 0 28 28"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M14 5.07987C14.8551 4.11105 16.1062 3.5 17.5 3.5C20.0773 3.5 22.1667 5.58934 22.1667 8.16667C22.1667 10.744 20.0773 12.8333 17.5 12.8333C16.1062 12.8333 14.8551 12.2223 14 11.2535M17.5 24.5H3.5V23.3333C3.5 19.4673 6.63401 16.3333 10.5 16.3333C14.366 16.3333 17.5 19.4673 17.5 23.3333V24.5ZM17.5 24.5H24.5V23.3333C24.5 19.4673 21.366 16.3333 17.5 16.3333C16.225 16.3333 15.0296 16.6742 14 17.2698M15.1667 8.16667C15.1667 10.744 13.0773 12.8333 10.5 12.8333C7.92267 12.8333 5.83333 10.744 5.83333 8.16667C5.83333 5.58934 7.92267 3.5 10.5 3.5C13.0773 3.5 15.1667 5.58934 15.1667 8.16667Z"
										stroke="#D1D5DB"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>

								<div className="flex flex-col">
									<span className="text-sm text-neutral-400">Guests</span>
									<span className="mt-1.5 text-lg font-semibold">{bookings?.guest} Guests</span>
								</div>
							</div>
						</div>
					</div>

					{/* ------------------------ */}
					<div className="space-y-6">
						<h3 className="text-2xl font-semibold">Request detail</h3>
						<div className="flex flex-col space-y-4">
							<div className="flex text-neutral-6000 dark:text-neutral-300">
								<span className="flex-1">Request Id:</span>
								<span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
									{bookings?.request_id}
								</span>
							</div>
							{/* <div className="flex text-neutral-6000 dark:text-neutral-300">
								<span className="flex-1">Date</span>
								<span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
							
                                </span>
							</div> */}
							<div className="flex text-neutral-6000 dark:text-neutral-300">
								<span className="flex-1">Total</span>
								<span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
									KD{bookings?.total}
								</span>
							</div>

						</div>
					</div>
					<div>
						{bookings?.status === 1 && (
							// <ButtonPrimary href="" className="w-full" onClick={handleMakePayment}>Make Payment</ButtonPrimary>
							<NcModal
								modalTitle="Confirm Your Booking"
								renderTrigger={(openModal) => (
									<ButtonPrimary href="" className="w-full" onClick={() => openModal()}>Make Payment</ButtonPrimary>
								)}
								renderContent={renderStripe}
							/>
						)}

						{bookings?.status === 0 && status !== "Canceled" && (
							<ButtonPrimary className="w-full" href="" onClick={handleCancel}>Cancel Booking</ButtonPrimary>
						)}

					</div>
				</div>
		);
	};

	return (
		<div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
			<main className="container mt-11 mb-24 lg:mb-32 ">
				<div className="max-w-4xl mx-auto">{renderContent()}</div>
			</main>
		</div>
	);
};

export default BookingRequestDetails;
